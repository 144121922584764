
exports.getId = function (cell) {
  return cell.id
}

exports.getClock = function (cell) {
  return cell.clock
}

exports.getSlot = function (cell, sc) {
  // Params:
  //   cell
  //   sc: slot coordinates
  return cell.layers[sc.z][sc.x][sc.y]
}
