
exports.validateCoords = function (z, x, y) {
  if (typeof z === 'object') {
    x = z.x
    y = z.y
    z = z.z
  }

  // Type

  if (typeof z !== 'number' ||
      typeof x !== 'number' ||
      typeof y !== 'number') {
    return false
  }

  if (isNaN(z) || isNaN(x) || isNaN(y)) {
    return false
  }

  // Limits

  if (z < 0 || x < 0 || y < 0) {
    return false
  }

  if (z >= 5 || x >= Math.pow(2, z) || y >= Math.pow(2, z)) {
    return false
  }

  return true
}

exports.fromCoordinates = function (z, x, y) {
  // Params:
  //   z: pos integer (or obj with keys z x y (or string))
  //   x: pos integer
  //   y: pos integer
  //
  // Return: string
  //
  if (typeof z === 'string') {
    // Already a slot ID but ensure format.
    exports.toCoordinates(z) // throws if invalid
    return z
  }

  if (typeof z === 'object') {
    x = z.x
    y = z.y
    z = z.z
  }

  if (x < 0 || y < 0 || z < 0) {
    throw new Error('Negative coordinate found. Coordinates must be positive.')
  }

  return z.toString() + '/' + x.toString() + '/' + y.toString()
}

exports.toCoordinates = function (slotId) {
  // Params: slotId (or coordinates obj)
  // Return: object with keys z, x, y
  //
  if (typeof slotId === 'object') {
    // Already a coord object.
    exports.fromCoordinates(slotId) // throws if invalid
    return slotId
  }

  var zxy = slotId.split('/')

  if (zxy.length !== 3) {
    throw new Error('Invalid slot ID: ' + slotId)
  }

  var z = parseInt(zxy[0], 10)
  var x = parseInt(zxy[1], 10)
  var y = parseInt(zxy[2], 10)

  if (isNaN(z * x * y)) {
    throw new Error('Invalid slot ID: ' + slotId)
  }

  if (x < 0 || y < 0 || z < 0) {
    throw new Error('Negative coordinate found. Coordinates must be positive.')
  }

  return { z: z, x: x, y: y }
}
