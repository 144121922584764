var observe = require('taataa-observe')
var ttcoords = require('taataa-coords')

var DEFAULT_LOCATION = {
  ux: 0,
  uy: 0,
  uz: 35,
  ur: 0
}

// Compute initial location from hash if available
var INITIAL_LOCATION = (function (hash) {
  var loc
  try {
    loc = ttcoords.ufCoordsFromString(hash)
  } catch (e) {
    return DEFAULT_LOCATION
  }
  return loc
})(window.location.hash.substr(1))

// Interface

module.exports = function (store) {
  // Set two-way:
  // 1. Update location on hash change to allow URL navigation.
  // 2. Observe location change and update URL hash accordingly
  //
  window.addEventListener('hashchange', function () {
    var state = store.getState()

    var ufs = ttcoords.ufCoordsToString(state.location)
    var hash = window.location.hash.substr(1)

    if (ufs === hash) {
      // hashchange caused implicitly, therefore do nothing
      return
    }

    // Explicit hashchange. User edited the URL. If valid coordinate,
    // take the user to the location.
    var ufh
    try {
      ufh = ttcoords.ufCoordsFromString(hash)
    } catch (e) {
      if (/cell/.test(e.message)) {
        // Invalid hash input. View should not react.
        // Reset the hash to current location to help user in formatting.
        window.location.hash = ufs
        return
      }
      console.error(e)
      return
    }

    store.dispatch({ type: 'VIEW_JUMP', location: ufh })
  })

  observe(store, ['location'], function (state) {
    var ufs = ttcoords.ufCoordsToString(state.location)
    window.location.hash = ufs
  })
}

module.exports.INITIAL_LOCATION = INITIAL_LOCATION
