var actions = require('../../../../../actions')
var effects = require('../lib/effects')
var tapspace = require('tapspace')
var ttco = require('taataa-coords')

module.exports = function (spaceItem, dispatch, origin) {
  // How taa can be dragged and what happens when drag ends.
  // Manipulates the given spaceItem.
  //
  // TODO convert dispatch to onDrop(targetCsc)
  //

  var sitem = spaceItem // short alias
  sitem.touch.start({
    translate: true,
    rotate: true,
    tap: false,
    preventDefault: true
  })

  var grid = new tapspace.geom.IGrid({
    xStep: 256,
    xPhase: 0,
    yStep: 256,
    yPhase: 0,
    rotateStep: Math.PI / 2,
    scaleStep: 10000,
    scalePhase: 1
  }, sitem) // grid must be defined on sitem cuz origin z can be anything.

  sitem.touch.on('gestureend', function () {
    sitem.snap(sitem.atNW(), grid)

    // TODO just wait a bit for further move.

    // Prevent further moves
    sitem.touch.stop()

    // End glow and show loading animation.
    sitem.endEffect()
    sitem.endEffect = effects.loading(sitem.effectContainer)

    // Get old cell-slot coords.
    var sourceCsc = sitem.sourceCsc
    // Get new cell-slot coords.
    var vec = sitem.atMid().toSpace()
    var targetUf = ttco.vectorToUfCoords(origin, vec)
    targetUf.uz = 36 // to board level
    var targetCsc = ttco.ufCoordsToCellSlot(targetUf)
    targetCsc.space = window.taataa.SPACEID

    if (ttco.cellSlotEqual(sourceCsc, targetCsc)) {
      // Same place, do nothing but remove the item.
      dispatch(actions.taaMoveCancel(sitem.moverId))
    } else {
      dispatch(actions.taaMoveRequest(
        sitem.moverId,
        sourceCsc,
        targetCsc
      ))
    }
  })
}
