
exports.deactivateItems = require('./deactivateItems')
exports.fetchUserId = require('./fetchUserId')
exports.taaAddRequest = require('./taaAddRequest')
exports.taaDropzoneStart = require('./taaDropzoneStart')
exports.taaMoveCancel = require('./taaMoveCancel')
exports.taaMoveRequest = require('./taaMoveRequest')
exports.moveLocation = require('./moveLocation')
exports.startSlotApp = require('./startSlotApp')
exports.stopSlotApp = require('./stopSlotApp')
