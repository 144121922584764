var calculateSlot = require('cluster-key-slot')

var USERMAP = typeof window === 'undefined' ? process.env.TAATAA_USERMAP
  : window.taataa.USERMAP

if (!USERMAP) {
  throw new Error('Missing environment variable TAATAA_USERMAP')
}

var usermap
try {
  usermap = JSON.parse(USERMAP)
} catch (err) {
  throw new Error('User map is not valid JSON: ' + USERMAP)
}

// NOTE Do only light validation here. Do heavy validation on the server-side
// because Ajv schema validator is a big piece of fat.
if (usermap.length < 1) {
  throw new Error('Invalid user map: ' + USERMAP)
}

// Ensure correct ranges and order

var i, first, last, slot
slot = 0
for (i = 0; i < usermap.length; i += 1) {
  first = usermap[i][0]
  last = usermap[i][1]
  if (first > last || last > 16383) {
    throw new Error('Invalid user map range: ' + USERMAP)
  }
  if (usermap[i][0] !== slot) {
    throw new Error('Invalid user map range: ' + USERMAP)
  }
  slot = last + 1
}

if (slot - 1 !== 16383) {
  throw new Error('Invalid user map range: ' + USERMAP)
}

// Module

module.exports = function (userId) {
  // Return user shard info for the userId
  var slot = calculateSlot(userId)

  var i, firstSlot, lastSlot
  for (i = 0; i < usermap.length; i += 1) {
    firstSlot = usermap[i][0]
    lastSlot = usermap[i][1]
    if (firstSlot <= slot && slot <= lastSlot) {
      return {
        slot: slot,
        slotRange: [firstSlot, lastSlot],
        userHost: usermap[i][2]
      }
    }
  }

  throw new Error('No user server found for ' + userId + '. ' +
    'Check the user map: ' + USERMAP)
}

module.exports.machines = function () {
  // For each slot-range-host entry
  return usermap.map(function (rh) {
    return {
      slotRange: [rh[0], rh[1]],
      userHost: rh[2]
    }
  })
}

module.exports.userHosts = function () {
  return module.exports.machines().map(function (machine) {
    return machine.userHost
  })
}
