//
var deactivate = require('./deactivate')

module.exports = function (store) {
  // Remove unnecessary items from time to time.

  setTimeout(function tick () {
    store.dispatch(deactivate())

    setTimeout(tick, 2000)
  }, 2000)
}
