// Defines rendering active items and their garbage collecting.
//
var spaceItems = require('./spaceItems')

var ttobj = require('taataa-objtools')
var observe = require('taataa-observe')

module.exports = function (view, store, itemGroup, slotGroup) {
  var renderedStoreItems = {} // To detect change.
  var renderedSpaceItems = {} // To be able to remove when deactivated.

  var createItem = function (storeItem, id) {
    // Item has properties:
    // - src
    // - slot
    // - csc
    //
    var newSpaceItem = spaceItems.create({
      storeItem: storeItem,
      view: view,
      store: store,
      itemGroup: itemGroup,
      slotGroup: slotGroup
    })
    renderedStoreItems[id] = storeItem
    renderedSpaceItems[id] = newSpaceItem
  }

  var updateItem = function (storeItem, id) {
    if (storeItem === renderedStoreItems[id]) {
      return // Filter. Item not changed, no need to update.
    }

    // Refresh
    var newItem = storeItem
    var oldItem = renderedStoreItems[id]
    var oldSpaceItem = renderedSpaceItems[id]

    var newSpaceItem = spaceItems.update({
      store: store,
      spaceItem: oldSpaceItem,
      oldStoreItem: oldItem,
      newStoreItem: newItem
    })
    // NOTE: spaceItem.update might return the same object.

    renderedStoreItems[id] = newItem
    renderedSpaceItems[id] = newSpaceItem
  }

  var removeItem = function (spaceItem, id) {
    spaceItems.remove({
      storeItem: renderedStoreItems[id], // for type data
      spaceItem: renderedSpaceItems[id]
    })
    delete renderedStoreItems[id]
    delete renderedSpaceItems[id]
  }

  observe(store, ['items'], function (state) {
    var newStoreItems = ttobj.subtract(state.items, renderedSpaceItems)
    var curStoreItems = ttobj.cut(state.items, renderedSpaceItems)
    var obsoleteSpaceItems = ttobj.subtract(renderedSpaceItems, state.items)
    // NOTE: ttobj.substract copies the value from the first param.
    // NOTE: ttobj.cut copies the value from the first param: activeItems.

    // Update changed items.
    ttobj.each(curStoreItems, updateItem)
    // Create new items
    ttobj.each(newStoreItems, createItem)
    // Collect garbage
    ttobj.each(obsoleteSpaceItems, removeItem)

    // DEBUG Show store item as a helpful title tooltip.
    if (state.developer) {
      ttobj.each(renderedSpaceItems, function (spaceItem, iid) {
        var el = view.getElementBySpaceItem(spaceItem)
        var storeItem = renderedStoreItems[iid]
        el.title = JSON.stringify(storeItem, null, 2) // indent 2 spaces
      })
    }
  })

  // DEBUG Show store item as a helpful title tooltip.
  // Render or hide the tooltips if developer mode changes.
  observe(store, ['developer'], function (state) {
    ttobj.each(renderedSpaceItems, function (spaceItem, iid) {
      var el = view.getElementBySpaceItem(spaceItem)

      if (state.developer) {
        var storeItem = renderedStoreItems[iid]
        el.title = JSON.stringify(storeItem, null, 2) // indent 2 spaces
      } else {
        el.removeAttribute('title')
      }
    })
  })
}
