// A space item that represents a dropped image. User has dragged an image
// file from the operating system and drops it into Taataa's tab.
//
var ttco = require('taataa-coords')
var tapspace = require('tapspace')
var cellmodel = require('taataa-cellmodel')
var effects = require('../lib/effects')

exports.create = function (opts) {
  // Create item
  //
  // Params:
  //  opts:
  //    storeItem: a loader
  //    view
  //    store: a Redux store
  //    itemGroup
  //
  var storeItem = opts.storeItem
  var view = opts.view
  var store = opts.store
  var itemGroup = opts.itemGroup

  // SpaceHTML already has a div container.
  // Create a 2nd div container for effects and place actual content inside.
  // Create a 3rd div container as a placeholder for future image.
  // TODO use the uploaded image directly
  var html = '<div><div></div></div>'
  var sitem = new tapspace.SpaceHTML(html, itemGroup)
  sitem.setSize(256, 256)

  var cont = view.getElementBySpaceItem(sitem)
  var effectCont = cont.firstChild
  sitem.effectContainer = effectCont
  sitem.endEffect = effects.none()

  // Class the container for easier styling and debugging.
  cont.className += ' tt-item tt-dropzoner'

  // Reference to item
  // NOTE we cannot just 'sitem.item = item' because the functional approach.
  sitem.itemId = storeItem.id

  // Position
  var orig = store.getState().origin
  var rect = ttco.cellSlotToViewRect(orig, storeItem.csc)
  sitem.translateScale([
    sitem.atNW(),
    sitem.atSE()
  ], [
    itemGroup.at(rect.x0, rect.y0),
    itemGroup.at(rect.x1, rect.y1)
  ])

  // TODO Touch capabilities. Allow move.
  // TODO Display tick that shows this is an virtual item.

  // TODO something more than just loading animation.
  sitem.endEffect = effects.loading(sitem.effectContainer)

  return sitem
}

exports.update = function (opts) {
  // Detect change and act accordingly.
  //
  // Params:
  //  opts:
  //    spaceItem
  //    store: a Redux store
  //    newStoreItem
  //    oldStoreItem
  //
  var sitem = opts.spaceItem
  var nu = opts.newStoreItem
  var ol = opts.oldStoreItem
  var ni

  if (ol.state === nu.state) {
    return sitem
  }

  // nu.state === 'waitingResponse' is the default

  if (nu.state === 'waitingPoll') {
    // A cellEvent was attached

    // Item tells what was created.
    ni = nu.cellEvent.item // Knights who say...

    if (ni === null) {
      // No change to the topmost item
      // TODO
      return sitem
    }

    // NOTE ni can be ''. The getSlotTileUrl provides the default image.
    var cellId = ttco.cellId(nu.csc)
    var tileSrc = cellmodel.getSlotTileUrl(cellId, nu.csc, ni)

    // Ditch the loading animation
    sitem.endEffect()

    // Insert the image
    var nuImg = new window.Image(256, 256)
    nuImg.src = tileSrc
    nuImg.draggable = false // Prevent issue #4
    sitem.effectContainer.replaceChild(nuImg, sitem.effectContainer.firstChild)

    return sitem
  }

  return sitem
}

exports.remove = function (opts) {
  // Remove rendered item.
  //
  // Params:
  //   opts:
  //     spaceItem: SpaceItem to remove
  //
  var sitem = opts.spaceItem
  sitem.remove()
}
