// These functions are used to find a locally available representation
// for a item. A full image for the item might not be available or it
// takes time to download it. In our quadtree structure a low-resolution
// version of the same image is often locally available as a part of
// already downloaded tile. We can use that portion.
//
var ttcoords = require('taataa-coords')

exports.find = function (slotGroup, csc) {
  // Find url of the image that contains the tile at cellSlotCoords.
  // Compute also the location of the tile in that image.
  //
  var children = slotGroup.getChildren()
  var pcsc = ttcoords.cellSlotParent(csc, false)
  var ppcsc = ttcoords.cellSlotParent(pcsc, false)
  var pppcsc = ttcoords.cellSlotParent(ppcsc, false)
  // Cscs of useful parent images that possibly exist.
  var coords = [csc, pcsc, ppcsc, pppcsc]

  // For each coord try to find a local image for it.
  // Note: if multiple such images exist, prefer ones closer to csc.
  // To implement this preference, loop first over coords, then over children.
  var i, j, child
  for (j = 0; j < coords.length; j += 1) {
    for (i = 0; i < children.length; i += 1) {
      child = children[i]
      if (ttcoords.cellSlotEqual(coords[j], child.csc)) {
        // Suitable image found.
        return {
          src: child.image.src, // taataa.SpaceImage#image.src
          rect: ttcoords.tileClip(child.csc, csc)
        }
      }
    }
  }

  // Nothing found
  return null
}

exports.generateHtml = function (tileSrc, clipRect) {
  // Return a HTML string that uses CSS clipping trick
  // to display only a portion of a large image.
  //
  // Params:
  //  tileSrc: URL of the large image
  //  clipRect: the boundaries of the clip within the large image.
  //
  var src = tileSrc
  var r = clipRect
  var w = r.x1 - r.x0
  // var h = r.y1 - r.y0 // The height equals width
  var mul = 256 / w
  var bgsize = 256 * mul
  var x = -r.x0 * mul
  var y = -r.y0 * mul
  var html = '<div style="' + // css clipping trick
    'background: url(' + src + ') ' + x + 'px ' + y + 'px;' +
    'background-size: ' + bgsize + 'px ' + bgsize + 'px;' +
    'width: 256px; height: 256px;' +
    '"></div>'
  return html
}
