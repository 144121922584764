var usermodel = require('taataa-usermodel')
var ttcoords = require('taataa-coords')
var axios = require('axios')

exports.addTaa = function (userId, file, csc) {
  // Returns a Promise.
  //   Resolved with:
  //   - an array of cell events
  //   - a failure code string
  //   Rejected with:
  //   - HTTP error.
  //
  var userUrl = usermodel.getUserUrl(userId)
  var actionUrl = userUrl + '/actions/addtaa'

  var data = new window.FormData()
  data.append('img', file) // document.getElementById('file').files[0])
  data.append('target', ttcoords.cellSlotId(csc))

  return axios.post(actionUrl, data).then(function (resp) {
    var cellEvents = resp.data
    return cellEvents
  })
}

exports.addTaaViaUrl = function (userId, url, csc) {
  // Returns a Promise.
  //   Resolved with:
  //   - an array of cell events
  //   - a failure code string
  //   Rejected with:
  //   - HTTP error.
  //
  var userUrl = usermodel.getUserUrl(userId)
  var actionUrl = userUrl + '/actions/addtaaviaurl'

  var params = new window.URLSearchParams() // x-www-form-urlencoded
  params.append('url', url)
  params.append('target', ttcoords.cellSlotId(csc))

  return axios.post(actionUrl, params).then(function (resp) {
    var cellEvents = resp.data
    return cellEvents
  })
}

exports.createUser = function () {
  // Return a Promise, resolve with userId
  //

  // Choose random user server
  // NOTE: although generated in the random server, the user node that
  // will be responsible for this user ID is determined by the user map
  // and probably is not the picked random server.
  var hostUrl = usermodel.getRandomUserHostUrl()
  var indexUrl = hostUrl + '/user'

  return axios.post(indexUrl).then(function (resp) {
    var userId = resp.data

    // Just assert
    if (typeof userId !== 'string' || userId.length !== 32) {
      throw new Error('Server generated invalid userId')
    }

    return userId
  })
}

exports.fetchUser = function (userId) {
  // Return a Promise resolved with a UserModel.
  //
  var userUrl = usermodel.getUserUrl(userId)
  return axios.get(userUrl).then(function (resp) {
    var user = usermodel.create(resp.data)
    return user
  })
}

exports.fetchTaskToPickTaa = function (userId, itemId, sourceCsc) {
  // Creates an action on back end.
  //
  // Return a Promise resolved with TaskSet
  //

  // DEBUG assert
  if (typeof userId !== 'string') throw new Error('Invalid userId: ' + userId)
  if (typeof itemId !== 'string') throw new Error('Invalid itemId: ' + itemId)

  var userUrl = usermodel.getUserUrl(userId)
  var actionUrl = userUrl + '/actions/picktaa'

  // For axios to use application/x-www-form-urlencoded
  var params = new URLSearchParams()
  params.append('itemId', itemId)
  params.append('source', ttcoords.cellSlotId(sourceCsc))

  return axios.post(actionUrl, params).then(function (resp) {
    return resp.data
  })
}

exports.solvePickTaaTaskByWait = function (userId, actionId, taskId) {
  // Solve task for action "pickTaa"
  //
  // Return a Promise resolved with inventoryDelta
  //
  var userUrl = usermodel.getUserUrl(userId)
  var actionUrl = userUrl + '/actions/picktaa/' + actionId

  // For axios to use application/x-www-form-urlencoded
  var params = new URLSearchParams()
  params.append('taskId', taskId)

  return axios.post(actionUrl, params).then(function (resp) {
    var inventoryDelta = resp.data
    return inventoryDelta
  })
}

exports.fetchTaskToDropTaa = function (userId, itemId, targetCsc) {
  // Creates an action on back end.
  //
  // Return a Promise resolved with TaskSet
  //

  // DEBUG assert
  if (typeof userId !== 'string') throw new Error('Invalid userId: ' + userId)
  if (typeof itemId !== 'string') throw new Error('Invalid itemId: ' + itemId)

  var userUrl = usermodel.getUserUrl(userId)
  var actionUrl = userUrl + '/actions/droptaa'

  // For axios to use application/x-www-form-urlencoded
  var params = new URLSearchParams()
  params.append('itemId', itemId)
  params.append('target', ttcoords.cellSlotId(targetCsc))

  return axios.post(actionUrl, params).then(function (resp) {
    return resp.data
  })
}

exports.solveDropTaaTaskByWait = function (userId, actionId, taskId) {
  // Solve task for action "dropTaa"
  //
  // Return a Promise resolved with inventoryDelta
  //
  var userUrl = usermodel.getUserUrl(userId)
  var actionUrl = userUrl + '/actions/droptaa/' + actionId

  // For axios to use application/x-www-form-urlencoded
  var params = new URLSearchParams()
  params.append('taskId', taskId)

  return axios.post(actionUrl, params).then(function (resp) {
    var inventoryDelta = resp.data
    return inventoryDelta
  })
}

exports.makeTaa = function (userId, file) {
  var userUrl = usermodel.getUserUrl(userId)
  var actionUrl = userUrl + '/actions/maketaa'

  var data = new window.FormData()
  data.append('img', file) // document.getElementById('file').files[0])

  return axios.post(actionUrl, data).then(function (resp) {
    return resp.data // inventory delta
  })
}

exports.moveTaa = function (userId, itemId, sourceCsc, targetCsc) {
  // Return a Promise
  //   Resolved with:
  //   - an array of cell events. Probably PICK and DROP.
  //   - a failure code string
  //   Rejected with:
  //   - HTTP error
  //
  var userUrl = usermodel.getUserUrl(userId)
  var actionUrl = userUrl + '/actions/movetaa'

  // For axios to use application/x-www-form-urlencoded
  var params = new URLSearchParams()
  params.append('itemId', itemId)
  params.append('source', ttcoords.cellSlotId(sourceCsc))
  params.append('target', ttcoords.cellSlotId(targetCsc))

  return axios.post(actionUrl, params).then(function (resp) {
    var cellEvents = resp.data
    return cellEvents
  })
}
