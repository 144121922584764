var userApi = require('../backend/userApi')
var fetchUserId = require('./fetchUserId')

module.exports = function (adderId, fileOrUrl, csc) {
  // A thunk.
  //
  // Params:
  //   adderId: item id for removal purposes
  //   fileOrUrl: File from a file input or URL string
  //   csc: location to add
  //
  return function (dispatch, getState) {
    dispatch(fetchUserId()).then(function (userId) {
      dispatch({
        type: 'TAA_ADD_REQUEST',
        adderId: adderId,
        csc: csc
      })

      var respPromise
      if (typeof fileOrUrl === 'string') {
        // URL
        respPromise = userApi.addTaaViaUrl(userId, fileOrUrl, csc)
      } else {
        respPromise = userApi.addTaa(userId, fileOrUrl, csc)
      }

      respPromise.then(function (cellEvents) {
        dispatch({
          type: 'TAA_ADD_REQUEST_OK',
          adderId: adderId,
          csc: csc
        })

        if (typeof cellEvents === 'string') {
          dispatch({
            type: 'TAA_ADD_FAILED',
            adderId: adderId,
            code: cellEvents
          })
        } else {
          var dropEvent = cellEvents[0]
          dispatch({
            type: 'TAA_ADD_SUCCESS',
            adderId: adderId,
            dropEvent: dropEvent,
            csc: csc
          })
        }
      }, function (err) {
        dispatch({
          type: 'TAA_ADD_REQUEST_FAILED',
          adderId: adderId,
          error: err
        })
      })
    })
  }
}
