var toolbar = require('./toolbar')

module.exports = function (store) {
  store.subscribe(function () {
    toolbar.render(store.dispatch, store.getState)
  })

  document.addEventListener('keydown', function (e) {
    e = e || window.event

    switch (e.keyCode) {
      case 192: // §
        store.dispatch({
          type: 'DEVELOPER_ONOFF'
        })
        break
      default:
        // nothing
    }
  })
}
