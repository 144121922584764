var NOOP = function () {}

exports.shake = function (htmlEl, iteration) {
  // To use shake, add this to styles:
  //
  // @keyframes shake {
  //   0% { transform: translate(1px, 1px) rotate(0deg); }
  //   10% { transform: translate(-1px, -2px) rotate(-1deg); }
  //   20% { transform: translate(-3px, 0px) rotate(1deg); }
  //   30% { transform: translate(3px, 2px) rotate(0deg); }
  //   40% { transform: translate(1px, -1px) rotate(1deg); }
  //   50% { transform: translate(-1px, 2px) rotate(-1deg); }
  //   60% { transform: translate(-3px, 1px) rotate(0deg); }
  //   70% { transform: translate(3px, 1px) rotate(-1deg); }
  //   80% { transform: translate(-1px, -1px) rotate(1deg); }
  //   90% { transform: translate(1px, 2px) rotate(0deg); }
  //   100% { transform: translate(1px, -2px) rotate(-1deg); }
  // }

  // First unshake
  htmlEl.style.animation = 'none'
  //
  var duration = 0.3
  // Then
  setTimeout(function () {
    htmlEl.style.animation = 'shake ' + duration.toFixed(1) + 's'
    htmlEl.style.animationIterationCount = iteration.toFixed(0)
  }, 0)
}

exports.glow = function (htmlEl) {
  //

  htmlEl.style.position = 'relative'
  // Then
  var overlay = document.createElement('div')
  overlay.style.width = '256px'
  overlay.style.height = '256px'
  overlay.style.backgroundColor = 'white'
  overlay.style.opacity = '0.9'
  overlay.style.position = 'absolute'
  overlay.style.top = '0px'
  overlay.style.transition = 'opacity 500ms linear'

  htmlEl.appendChild(overlay)

  var flip = true
  var intervalId = setInterval(function () {
    if (flip) {
      overlay.style.opacity = '0.0'
    } else {
      overlay.style.opacity = '0.9'
    }
    flip = !flip
  }, 500)

  return function () {
    htmlEl.removeChild(overlay)
    clearTimeout(intervalId)
  }
}

exports.glowMove = function (htmlEl) {
  //

  htmlEl.style.position = 'relative'
  // Then
  var overlay = document.createElement('div')
  overlay.style.width = '256px'
  overlay.style.height = '256px'
  overlay.style.backgroundImage = 'url(/static/move-256x256.png)'
  overlay.style.backgroundColor = 'rgba(256,256,256,0.1)'
  overlay.style.opacity = '1.0'
  overlay.style.position = 'absolute'
  overlay.style.top = '0px'
  overlay.style.transition = 'opacity 500ms linear'

  htmlEl.appendChild(overlay)

  var flip = false
  var intervalId = setInterval(function () {
    if (flip) {
      overlay.style.opacity = '0.2'
    } else {
      overlay.style.opacity = '1.0'
    }
    flip = !flip
  }, 800)

  // Immediate first flip, then after interval
  setTimeout(function () {
    overlay.style.opacity = '0.2'
  }, 0)

  return function () {
    htmlEl.removeChild(overlay)
    clearTimeout(intervalId)
  }
}

exports.loading = function (htmlEl) {
  //

  htmlEl.style.position = 'relative' // TODO is needed?

  var overlay = document.createElement('div')
  var le = overlay.style
  le.width = '256px'
  le.height = '256px'
  le.position = 'absolute'
  le.top = '0px'
  le.left = '0px'

  le.backgroundColor = 'rgba(0,0,0,0.2)'
  // le.backgroundImage = 'url(/static/loader-98x98.gif)'
  // le.backgroundPosition = '79px 77px'
  le.backgroundImage = 'url(/static/taa_loader_32x32.gif)'
  le.backgroundRepeat = 'no-repeat'
  le.backgroundPosition = '112px 112px'

  htmlEl.appendChild(overlay)

  return function () {
    htmlEl.removeChild(overlay)
  }
}

exports.loadingCcw = function (htmlEl) {
  // TODO

  htmlEl.style.position = 'relative' // TODO is needed?

  var overlay = document.createElement('div')
  var le = overlay.style
  le.width = '256px'
  le.height = '256px'
  le.position = 'absolute'
  le.top = '0px'
  le.left = '0px'

  le.backgroundColor = 'rgba(0,0,0,0.5)'
  le.backgroundImage = 'url(/static/taa_loader_32x32.gif)'
  le.backgroundRepeat = 'no-repeat'
  le.backgroundPosition = '112px 112px'

  htmlEl.appendChild(overlay)

  return function () {
    htmlEl.removeChild(overlay)
  }
}

exports.none = function () {
  // Reset endEffect with result of none() to prevent double endEffect calls.
  return NOOP
}
