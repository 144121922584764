
exports.build = function (width, height, fill) {
  // Create a column 2d array and fill it.
  var i, j, col
  var res = []
  for (i = 0; i < width; i += 1) {
    col = []
    for (j = 0; j < height; j += 1) {
      col.push(fill)
    }
    res.push(col)
  }
  return res
}

exports.downscale = function (arr2, kernel) {
  // Compute a quadrant-size 2d array where every four element squares
  // of the original are reduced with the kernel function to one.
  //
  // Params
  //   arr2: a 2d column array
  //   kernel: fn(i00, i01, i10, i11) where 0 1 denote square positions ixy
  //
  const w = arr2.length
  const h = arr2[0].length

  var i, j, col
  var res = []
  for (i = 0; i < w; i += 2) {
    col = []
    for (j = 0; j < h; j += 2) {
      col.push(kernel(
        arr2[i][j],
        arr2[i][j + 1],
        arr2[i + 1][j],
        arr2[i + 1][j + 1]
      ))
    }
    res.push(col)
  }
  return res
}

exports.forEach = function (arr2, iteratee) {
  // Iterate over elements in column 2d array per column basis
  //
  // Params:
  //  arr2: a 2d array
  //  iteratee: fn(item, i, j) where i is column index and j is row index.
  //
  var i, j
  for (i = 0; i < arr2.length; i += 1) {
    for (j = 0; j < arr2[i].length; j += 1) {
      iteratee(arr2[i][j], i, j)
    }
  }
}

exports.map = function (arr2, iteratee) {
  // Return a 2d array where every element is mapped by iteratee.
  var i, j, col
  var res = []
  for (i = 0; i < arr2.length; i += 1) {
    col = []
    for (j = 0; j < arr2[i].length; j += 1) {
      col.push(iteratee(arr2[i][j], i, j))
    }
    res.push(col)
  }
  return res
}

exports.shallowCopy = function (arr2) {
  // Recreate the arrays but use the same the elements.
  return exports.map(arr2, function (el) { return el })
}

exports.transpose = function (arr2) {
  // Returned 2d array represents elements in rows instead of columns.
  var i, j, row
  var rows = []
  var h = arr2[0].length // first column size
  for (i = 0; i < h; i += 1) {
    row = []
    for (j = 0; j < arr2.length; j += 1) {
      row.push(arr2[j][i])
    }
    rows.push(row)
  }
  return rows
}
