// DEBUG
// require('ghoulog')

// Polyfills
window.URLSearchParams = require('url-search-params') // for Axios

var boardview = require('./processes/boardview')
var cellpoller = require('./processes/cellpoller')
var hashrouter = require('./processes/hashrouter')
var slotActivator = require('./processes/slotBuffering/activator')
var slotDeactivator = require('./processes/slotBuffering/deactivator')
var developer = require('./processes/developer')
var storedux = require('./storedux')

var store = storedux.create({
  location: hashrouter.INITIAL_LOCATION
})

hashrouter(store)
cellpoller(store)
boardview(store)
slotActivator(store)
slotDeactivator(store)
developer(store)

// This will trigger the subscribers the first time.
// The implicit @@redux/INIT action does not trigger them.
// NOTE that we cannot use this IGNITE to initialize state because
// the state should be initialized either with @@redux/INIT or createStore.
store.dispatch({ type: 'IGNITE' })
