var userApi = require('../backend/userApi') // TODO modularize

// Allow callers of fetchUserId be served when the user id arrives.
var fetchUserIdPromise = null

// Actions

module.exports = function () {
  // Return thunk that returns a promise that is resolved with user ID.
  return function (dispatch, getState) {
    var state = getState()

    if (state.userId !== null) {
      // Already has userId.
      return Promise.resolve(state.userId)
    }

    if (fetchUserIdPromise) {
      return fetchUserIdPromise
    }

    dispatch({ type: 'USER_ID_FETCHING' })

    fetchUserIdPromise = userApi.createUser().then(function (userId) {
      dispatch({ type: 'USER_ID_SET', userId: userId })
      return userId
    }, function (err) {
      dispatch({ type: 'USER_ID_ERROR', error: err })
      throw err
    })

    return fetchUserIdPromise
  }
}
