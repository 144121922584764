var actions = require('./actions')
var ttobj = require('taataa-objtools')
var ttco = require('taataa-coords')

module.exports = function (store) {
  // Manage polling process.
  //
  var mem

  mem = {
    pollingTick: -1, // store default is 0
    activeCells: null
  }

  store.subscribe(function () {
    // Handle fetching.
    // Listen activeCells and fetch new ones immediately.
    //
    var state = store.getState()

    // NOTE: Fetching test must be before mem handling.
    // Otherwise state.fetching flip does not cause fetching.
    if (!state.fetching) {
      return
    }

    if (state.activeCells === mem.activeCells) {
      return
    }
    var nuActiveCells = ttobj.subtract(state.activeCells, mem.activeCells)
    mem.activeCells = state.activeCells

    var fetchCellIds = Object.keys(state.activeCells).filter(function (cid) {
      if (state.cellsMeta.hasOwnProperty(cid)) {
        // Cell exists locally.
        if (state.cellsMeta[cid].requestState === 'INIT') {
          // Cell is not fetched yet.
          return true
        } // else the cell is already fetched or fetching.
        return false
      } // else no mention about the cell. Fetch.
      return true
    })

    // Poll cells that are already loaded but just revealed.
    // Use cached cell but fetch the new events immediately.
    var pollCellIds = Object.keys(nuActiveCells).filter(function (cid) {
      if (state.cellsMeta.hasOwnProperty(cid)) {
        if (state.cellsMeta[cid].requestState === 'COMPLETE') {
          return true
        }
      }
      return false
    })

    fetchCellIds.forEach(function (cellId) {
      store.dispatch(actions.fetch(cellId))
    })

    pollCellIds.forEach(function (cellId) {
      store.dispatch(actions.poll(cellId))
    })
  })

  store.subscribe(function () {
    // Handle polling.
    //
    var state = store.getState()

    if (!state.polling) {
      return
    }

    if (state.pollingTick === mem.pollingTick) {
      return
    }
    mem.pollingTick = state.pollingTick

    // Requirements:
    // - the more the cell is visible, the more frequent it should be polled.
    //
    // Pseudo:
    // - Pick one slot at random.
    // - If no slots, stop.
    // - Compute cellId
    // - Check if cell available
    //   - If no, stop.
    //   - If available, check if already polling.
    //     - If polling, stop.
    //     - If not, poll.
    //
    // TODO Weight cell selection with:
    // - local cell manipulations
    // - event frequency (evs / sec)
    //
    var csids = Object.keys(state.activeSlots)

    if (csids.length < 1) {
      return // no slots yet => no cells yet => do not poll
    }

    var csid = csids[Math.floor(Math.random() * csids.length)]
    var cellId = ttco.cellId(ttco.cellSlotCoords(csid))

    if (!state.cellsMeta.hasOwnProperty(cellId)) {
      return // cell not available yet
    }

    var rs = state.cellsMeta[cellId].requestState
    if (rs === 'COMPLETE') {
      store.dispatch(actions.poll(cellId))
    }
  })

  var tick = function () {
    if (store.getState().polling) {
      store.dispatch({
        type: 'POLLING_TICK'
      })
    }
    setTimeout(tick, 2000)
  }

  setTimeout(tick, 2000)
}
