var isImageOk = require('taataa-isimageok')

module.exports = function (slots) {
  // Params:
  //   slots: a map from cell-slot id to anything.
  //
  // Return
  //   true if all tiles of given slots have finished downloading.
  //   false if any tile image is not complete.
  //
  var i, img, csid
  var spaceEl = document.getElementById('space')
  var imgs = spaceEl.getElementsByTagName('img')

  for (i = 0; i < imgs.length; i += 1) {
    img = imgs[i]
    csid = img.dataset.csid
    if (csid && slots.hasOwnProperty(csid) && !isImageOk(img)) {
      return false
    }
  }
  return true
}
