module.exports = function (itemId) {
  // Practically removes the item.
  // Params:
  //  csc: CellSlotCoords
  //
  return {
    type: 'STOP_SLOT_APP',
    itemId: itemId
  }
}
