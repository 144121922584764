var ttcoords = require('taataa-coords')
var observe = require('taataa-observe')

module.exports = function (view, store) {
  // Jump to certain given location.
  //
  var space = view.getParent()

  observe(store, ['jumpto'], function (state) {
    var vc = ttcoords.ufCoordsToView(state.origin, state.jumpto)
    view.resetTransform()
    view.translate(view.atMid(), space.at(vc.mid.x, vc.mid.y))
    view.scale(view.atMid(), vc.scale)
    view.rotate(view.atMid(), vc.rotation)
  })
}
