
exports.adder = function (csc) {
  return {
    id: 'adder' + Math.random().toString().substring(2),
    type: 'adder',
    state: 'waitingFileInput', // waitingResponse, waitingPoll, removable
    csc: csc,
    creationTime: Date.now(),
    addedItemId: null,
    pending: false,
    removable: false
  }
}

exports.dropzoner = function (id, csc) {
  // Created when user drags an image file from OS and drops it to Taataa.
  return {
    id: id,
    type: 'dropzoner',
    state: 'waitingResponse',
    csc: csc, // Needed by item renderers to position the item.
    creationTime: Date.now(),
    cellEvent: null,
    pending: true,
    removable: false
  }
}

exports.mover = function (slot, nexterId, csc) {
  // An item made movable.
  return {
    id: 'mover' + Math.random().toString().substring(2),
    type: 'mover',
    state: 'picked', // waitingResponse, waitingPoll, removable
    csc: csc, // Needed by item renderers to position the item.
    creationTime: Date.now(),
    slot: slot,
    cellEvent: null,
    nexterId: nexterId,
    pending: false,
    removable: false
  }
}

exports.nexter = function (csc) {
  // Placed below the mover.
  return {
    id: 'nexter' + Math.random().toString().substring(2),
    type: 'nexter',
    state: 'waitingResponse',
    csc: csc, // Needed by item renderers to position the item.
    creationTime: Date.now(),
    cellEvent: null,
    pending: false,
    removable: false
  }
}

exports.slotter = function (dropEvent, csc) {
  // Used to temporarily represent local state eg. after upload or pick
  // but still allow common slot actions.
  return {
    id: 'slotter' + Math.random().toString().substring(2),
    type: 'slotter',
    csc: csc,
    cellEvent: dropEvent,
    pending: true,
    removable: false
  }
}
