// Helps to read cell data.
// Targeted for both client and server side.

var cellmapper = require('taataa-cellmapper')
var ttcoords = require('taataa-coords')
var deftiles = require('taataa-defaulttiles')
var slotid = require('taataa-slotid')

var NODE_ENV = typeof window === 'undefined' ? process.env.NODE_ENV
  : window.taataa.NODE_ENV
var PROTOCOL = NODE_ENV === 'development' ? 'http' : 'https'

// Ensure NODE_ENV is set. Hard do debug otherwise.
if (typeof NODE_ENV !== 'string') {
  let msg = 'Missing env var NODE_ENV'
  throw new Error(msg)
}

// Setup

var getCellCoords = function (cellId) {
  var parts = cellId.split('/')

  if (parts.length !== 4) {
    throw new Error('Invalid cell ID: ' + cellId)
  }

  return {
    space: parts[0],
    cz: parseInt(parts[1], 10),
    cx: parseInt(parts[2], 10),
    cy: parseInt(parts[3], 10)
  }
}

var getCellId = function (space, cz, cx, cy) {
  if (typeof space === 'object') {
    cz = space.cz
    cx = space.cx
    cy = space.cy
    space = space.space
  }

  return space + '/' + cz + '/' + cx + '/' + cy
}

var slotItemToCellItem = function (slot) {
  // Convert a slot item to a more verbose and easy use cell item.
  // Null is returned for a slot that is not an item.
  //
  // Params: slot: array, a SlotItem
  //
  // Return: object, a CellItem
  //
  if (typeof slot === 'string') {
    return null
  }

  var l = slot.length
  return {
    id: slot[0],
    hash: slot[1],
    rotation: l > 2 ? slot[2] : 0,
    challenge: l > 3 ? slot[3] : null,
    type: l > 3 ? (l < 5 ? 'daa' : slot[4]) : 'taa',
    state: l > 5 ? slot[5] : null
  }
}

var cellItemToSlotItem = function (ci) {
  // Convert item representation from object to array.
  var s = [ci.id, ci.hash]

  if (ci.state !== null) {
    s.push(ci.rotation, ci.challenge, ci.type, ci.state)
  } else if (ci.type === 'taa') {
    if (ci.rotation !== 0) {
      s.push(ci.rotation)
    }
  } else if (ci.type === 'daa') {
    s.push(ci.rotation, ci.challenge)
  } else {
    s.push(ci.rotation, ci.challenge, ci.type)
  }

  return s
}

// Class

var CellModel = function (cell) {
  // Params: cell: a public cell object
  // TODO optional meta cell with stacks
  this.cellServerInfo = cellmapper(cell.id)
  this.cell = cell
  this.coords = getCellCoords(cell.id)

  // TODO allow external options
  this.opts = {
    defaultTiles: ['black.jpg']
  }
}

var proto = CellModel.prototype

proto.getCellUrl = function () {
  return PROTOCOL + '://' + this.cellServerInfo.cellHost +
    '/cell/' + this.cell.id
}

proto.getItemUrl = function (slotCoords, itemId) {
  return this.getSlotUrl(slotCoords) + '/' + itemId
}

proto.getSlotUrl = function (slotCoords) {
  return this.getCellUrl() + '/' + slotid.fromCoordinates(slotCoords)
}

proto.getTileUrl = function (hash) {
  return PROTOCOL + '://' + this.cellServerInfo.tileHost + '/' +
    this.cell.id + '/' + hash + '.jpg'
}

proto.getSlot = function (coordsOrId) {
  // Return public slot contents. If a stack, returns topmost item.
  var sc = slotid.toCoordinates(coordsOrId)
  return this.cell.layers[sc.z][sc.x][sc.y]
}

proto.getSlotId = function (coordsOrId) {
  return slotid.fromCoordinates(coordsOrId)
}

proto.getSlotType = function (coordsOrId) {
  var s = this.getSlot(coordsOrId)
  if (typeof s === 'string') {
    return 'tile'
  }
  return 'stack'
}

proto.getSlotTileUrl = function (coordsOrId) {
  // URL to the public tile of the slot.
  // TODO rename to getTopTileUrl
  //
  var t, sc, s, csc
  sc = slotid.toCoordinates(coordsOrId)
  s = this.cell.layers[sc.z][sc.x][sc.y]
  csc = ttcoords.cellSlot(this.coords, sc)

  if (typeof s === 'string') {
    if (s === '') {
      // Empty, use default
      t = deftiles.pickDefaultTile(csc)
      return PROTOCOL + '://' + this.cellServerInfo.tileHost + '/' + t
    }
    // Hash
    return this.getTileUrl(s)
  }
  // Item
  if (s.length === 0) {
    // Empty stack. Use default tile.
    t = deftiles.pickDefaultTile(csc)
    return PROTOCOL + '://' + this.cellServerInfo.tileHost + '/' + t
  }
  // Item has hash as its second argument
  return this.getTileUrl(s[1])
}

proto.getCellItem = function (slotCoords) {
  // Return null if no item at slot.
  //
  var slot = this.cell.layers[slotCoords.z][slotCoords.x][slotCoords.y]
  return slotItemToCellItem(slot)
}

// Entrypoint

var create = function (rawcell, opts) {
  return new CellModel(rawcell, opts)
}

create.getCellId = getCellId
create.getCellCoords = getCellCoords

create.getCellUrl = function (cellId) {
  var cellServerInfo = cellmapper(cellId)
  return PROTOCOL + '://' + cellServerInfo.cellHost + '/cell/' + cellId
}

create.getItemUrl = function (cellId, slotCoordsOrId, itemId) {
  return create.getSlotUrl(cellId, slotCoordsOrId) + '/' + itemId
}

create.getSlotUrl = function (cellId, slotCoordsOrId) {
  var slotId = slotid.fromCoordinates(slotCoordsOrId)
  return create.getCellUrl(cellId) + '/' + slotId
}

create.getTileUrl = function (cellId, hash) {
  var cellServerInfo = cellmapper(cellId)
  return PROTOCOL + '://' + cellServerInfo.tileHost + '/' +
    cellId + '/' + hash + '.jpg'
}

create.getSlotTileUrl = function (cellId, slotCoordsOrId, slot) {
  var cellServerInfo = cellmapper(cellId)
  var cc = getCellCoords(cellId)
  var sc = slotid.toCoordinates(slotCoordsOrId)
  var csc = ttcoords.cellSlot(cc, sc)
  var t

  if (typeof slot === 'string') {
    if (slot === '') {
      // Empty, use default
      t = deftiles.pickDefaultTile(csc)
      return PROTOCOL + '://' + cellServerInfo.tileHost + '/' + t
    }
    // Hash
    return create.getTileUrl(cellId, slot)
  }
  // Item
  if (slot.length === 0) {
    // Empty stack. Use default tile.
    t = deftiles.pickDefaultTile(csc)
    return PROTOCOL + '://' + cellServerInfo.tileHost + '/' + t
  }
  // Item has hash as its second argument
  return create.getTileUrl(cellId, slot[1])
}

create.slotItemToCellItem = slotItemToCellItem
create.cellItemToSlotItem = cellItemToSlotItem

module.exports = create
