// Route calls based on item type.
//
var adder = require('./adder')
var dropzoner = require('./dropzoner')
var mover = require('./mover')
var nexter = require('./nexter')
var slotter = require('./slotter')

var items = {
  adder: adder,
  dropzoner: dropzoner,
  mover: mover,
  nexter: nexter,
  slotter: slotter
}

exports.create = function (opts) {
  var type = opts.storeItem.type
  return items[type].create(opts)
}

exports.update = function (opts) {
  var type = opts.newStoreItem.type
  return items[type].update(opts)
}

exports.remove = function (opts) {
  var type = opts.storeItem.type
  return items[type].remove(opts)
}
