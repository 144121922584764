var cellmodel = require('taataa-cellmodel')
var ttco = require('taataa-coords')
var tapspace = require('tapspace')
var effects = require('../lib/effects')

exports.create = function (opts) {
  // Create item
  //
  // Params:
  //  opts:
  //    storeItem: a slotter
  //    view
  //    store: a Redux store
  //    itemGroup
  //
  var storeItem = opts.storeItem
  var view = opts.view
  var store = opts.store
  var itemGroup = opts.itemGroup

  var cellId = ttco.cellId(storeItem.csc)
  var slotId = ttco.slotId(storeItem.csc)
  var slot = storeItem.cellEvent.item
  var tileSrc = cellmodel.getSlotTileUrl(cellId, slotId, slot)

  // SpaceHTML already has a div container.
  // Create a 2nd container for effects and place actual content inside.
  // Set draggable false to prevent issue #4
  var html = '<div><img src="' + tileSrc + '" draggable="false"></div>'
  var sitem = new tapspace.SpaceHTML(html, itemGroup)
  sitem.setSize(256, 256)

  var cont = view.getElementBySpaceItem(sitem)
  var effectCont = cont.firstChild
  sitem.effectContainer = effectCont
  sitem.endEffect = effects.none()

  // Class the container for easier styling and debugging.
  cont.className += ' tt-item tt-slotter'

  // Reference to item
  // NOTE we cannot just 'sitem.item = item' because the functional approach.
  sitem.itemId = storeItem.id

  // Position
  var orig = store.getState().origin
  var rect = ttco.cellSlotToViewRect(orig, storeItem.csc)
  sitem.translateScale([
    sitem.atNW(),
    sitem.atSE()
  ], [
    itemGroup.at(rect.x0, rect.y0),
    itemGroup.at(rect.x1, rect.y1)
  ])

  // TODO Touch capabilities. Allow move.
  // TODO Display tick that shows this is an virtual item.

  return sitem
}

exports.update = function (opts) {
  // Detect change and act accordingly.
  //
  // Params:
  //  opts:
  //    spaceItem
  //    store: a Redux store
  //    newStoreItem
  //    oldStoreItem
  //
  var sitem = opts.spaceItem
  return sitem
}

exports.remove = function (opts) {
  // Remove rendered item.
  //
  // Params:
  //   opts:
  //     spaceItem: SpaceItem to remove
  //
  var sitem = opts.spaceItem
  sitem.remove()
}
