
exports.all = function (obj, test) {
  // Returns false immediately if any of the tests return false instead of true.
  var k
  for (k in obj) {
    if (obj.hasOwnProperty(k)) {
      if (!test(obj[k], k)) {
        return false
      }
    }
  }

  return true
}

exports.any = function (obj, test) {
  // Returns true immediately if any of the tests return true instead of false.
  var k
  for (k in obj) {
    if (obj.hasOwnProperty(k)) {
      if (test(obj[k], k)) {
        return true
      }
    }
  }

  return false
}

exports.diff = function (oldObj, newObj) {
  // Return added and deleted keys.
  //   added: <array>
  //   removed: <array>
  //
  var added = []
  var removed = []
  var k

  for (k in oldObj) {
    if (oldObj.hasOwnProperty(k)) {
      if (!newObj.hasOwnProperty(k)) {
        removed.push(k)
      }
    }
  }

  for (k in newObj) {
    if (newObj.hasOwnProperty(k)) {
      if (!oldObj.hasOwnProperty(k)) {
        added.push(k)
      }
    }
  }

  return {
    added: added,
    removed: removed
  }
}

exports.each = function (obj, iteratee) {
  var k
  for (k in obj) {
    if (obj.hasOwnProperty(k)) {
      iteratee(obj[k], k)
    }
  }
}

exports.filter = function (obj, iteratee) {
  // Create a new object with those properties of obj for that iteratee
  // returns true.
  //
  // Params:
  //   obj
  //   iteratee: fn(value, key) -> bool
  //
  var res = {}
  var k
  for (k in obj) {
    if (obj.hasOwnProperty(k)) {
      if (iteratee(obj[k], k)) {
        res[k] = obj[k]
      }
    }
  }
  return res
}

exports.find = function (obj, iteratee) {
  // Return first value for that the iteratee returns true.
  // Null if not found.
  //
  // Params:
  //   obj
  //   iteratee: fn(value, key) -> bool
  //
  var k
  for (k in obj) {
    if (obj.hasOwnProperty(k)) {
      if (iteratee(obj[k], k)) {
        return obj[k]
      }
    }
  }
  return null
}

exports.insert = function (obj, key, value) {
  // Return a new object that contains the keys and values of the given object
  // with an addition of the given key and value. If key already exists,
  // the value will be overwritten.
  // The given object is not altered.
  //
  var a = {}
  a[key] = value
  return Object.assign({}, obj, a)
}

exports.max = function (obj, evaluate) {
  // Return the value in obj where the evaluate fn is maximized.
  // Return null if obj is empty.
  // If multiple values have equal evaluation, the first is returned.
  // However, note that JS spec does not guarantee object key order.
  //
  // Params:
  //   obj
  //   evaluate: fn(value, key) -> number
  //
  var k, ev
  var maxValue = null
  var maxEv = null
  for (k in obj) {
    if (obj.hasOwnProperty(k)) {
      ev = evaluate(obj[k], k)
      if (maxEv === null || maxEv < ev) {
        maxEv = ev
        maxValue = obj[k]
      }
    }
  }
  return maxValue
}

exports.subtract = function (obja, objb) {
  // Remove objb properties from obja and return the result
  // Does not alter the given objects.
  var res = {}
  var k
  for (k in obja) {
    if (obja.hasOwnProperty(k)) {
      if (!objb.hasOwnProperty(k)) {
        res[k] = obja[k]
      }
    }
  }
  return res
}

exports.cut = function (obja, objb) {
  // Return a new object with keys that exist on both objects.
  // Copy the values from the first object.
  // Do not alter the given objects.
  //
  var res = {}
  var k
  for (k in obja) {
    if (obja.hasOwnProperty(k) && objb.hasOwnProperty(k)) {
      res[k] = obja[k]
    }
  }
  return res
}

exports.remove = function (obj, key) {
  var res = Object.assign({}, obj)
  delete res[key]
  return res
}
