var slotid = require('taataa-slotid')
var array2d = require('taataa-array2d')

// TODO temp items that are separately displayed until tile update
// This way the higher layers look okay.

module.exports = function (cell, cellEvents) {
  // Executes cell events on the given cell state.
  // Returns the new cell.
  // Pure function, does not modify args.
  //
  // Params:
  //   cell: a public cell object from a cell server
  //   cellEvents: array
  //
  // Returns:
  //   {
  //     cell: new cell (is the original cell if no changes)
  //     changedSlots: <array of in-cell slot coordinate objects>
  //   }
  //
  if (cellEvents.length === 0) {
    // Nothing changed
    return {
      cell: cell,
      changedSlots: []
    }
  }

  // A base onto build the new state.
  var newLayers = cell.layers.map(function (layer, z) {
    return array2d.shallowCopy(layer)
  })

  // Push coords of every changed slot to help update. TODO remove, not needed
  var changedSlots = []

  var handleDrop = function (cev) {
    var sc = slotid.toCoordinates(cev.slot)
    newLayers[sc.z][sc.x][sc.y] = cev.item
    changedSlots.push(sc)
  }

  var handlePick = function (cev) {
    if (cev.hasOwnProperty('nextItem') && cev.nextItem !== null) {
      // 1. Backward compatibility: early cellEvents did not have nextItem
      // 2. Null nextItem means that pick happened but top tile didn't change.
      // 3. Empty string means that stack became empty.
      var sc = slotid.toCoordinates(cev.slot)
      newLayers[sc.z][sc.x][sc.y] = cev.nextItem
      changedSlots.push(sc)
    }
  }

  var handleTile = function (cev) {
    var sc = slotid.toCoordinates(cev.slot)
    newLayers[sc.z][sc.x][sc.y] = cev.tile
    changedSlots.push(sc)
  }

  var handleTiles = function (cev) {
    // cev.tiles is array of [slotId, tileImageHash] arrays
    cev.tiles.forEach(function (tilecev) {
      var sc = slotid.toCoordinates(tilecev[0])
      newLayers[sc.z][sc.x][sc.y] = tilecev[1]
      changedSlots.push(sc)
    })
  }

  cellEvents.forEach(function (cev) {
    switch (cev.type) {
      case 'DROP':
        return handleDrop(cev)
      case 'PICK':
        return handlePick(cev)
      case 'TILES':
        return handleTiles(cev)
      case 'TILE':
        return handleTile(cev)
      case 'INIT':
      default:
        // Nothing
    }
  })

  // Logical time for the new state.
  // Make compatible with v0 events that do not have clock.
  var latestEvent = cellEvents[cellEvents.length - 1]
  var newClock
  if (latestEvent.hasOwnProperty('clock')) {
    newClock = latestEvent.clock
  } else {
    console.warn('Detected a deprecated cell event without clock')
    newClock = cell.clock + cellEvents.length
  }

  // Shallow copy other cell properties.
  var newCell = Object.assign({}, cell)
  // Update changed properties.
  newCell.clock = newClock
  newCell.layers = newLayers

  return {
    cell: newCell,
    changedSlots: changedSlots
  }
}
