var reducer = require('./reducer')
var redux = require('redux')
var reduxThunk = require('redux-thunk').default

exports.create = function (opts) {
  // Create and init a Redux store.
  //
  // Params:
  //  opts:
  //    location: a user-friendly coordinate
  //

  var initialState = {
    // Cells that we are rendering slots from.
    activeCells: {},
    // Slots that we are rendering.
    activeSlots: {},

    // All items that we handle as objects separate from slot tiles.
    // Includes, excited items (to be picked), loose items, and
    // items in inventory.
    items: {},

    // Cell cache. A map from cell id to public cell.
    cells: {},

    // Cell local meta data. E.g polling state.
    // A map from cell id to enum 'INIT', 'FETCHING', 'POLLING', 'COMPLETE'
    cellsMeta: {},

    // Developer mode.
    // Controls developer toolbar for example.
    developer: window.taataa.NODE_ENV !== 'production',

    // Optionally, cell fetching can be turned off.
    fetching: true,

    // Changed when view needs to jump to explicitly specified location.
    jumpto: opts.location,

    location: opts.location,
    origin: {
      ux: Math.floor(opts.location.ux),
      uy: Math.floor(opts.location.uy),
      uz: Math.floor(opts.location.uz),
      ur: 0 // Always zero
    },

    polling: true,
    pollingTick: 0,

    userId: null,
    userIdFetching: false,

    viewport: {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  return redux.createStore(
    reducer,
    initialState, // initial state, initialized at reducer
    redux.applyMiddleware(reduxThunk)
  )
}
