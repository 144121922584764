module.exports = function (state, action) {
  // Params:
  //   state
  //   action
  //     type
  //     moverId
  //     nexterId
  //     movedId
  //     sourceCsc
  //     targetCsc
  //
  var oldNexter = state.items[action.nexterId]
  var oldMover = state.items[action.moverId]

  var newNexter = Object.assign({}, oldNexter, {
    state: 'waitingResponse',
    pending: true
  })
  var newMover = Object.assign({}, oldMover, {
    csc: action.targetCsc,
    state: 'waitingResponse',
    pending: true
  })

  var newItems = {}
  newItems[newNexter.id] = newNexter
  newItems[newMover.id] = newMover

  return Object.assign({}, state, {
    items: Object.assign({}, state.items, newItems)
  })
}
