module.exports = function (state, action) {
  // Remove the items.
  //
  if (!state.items.hasOwnProperty(action.moverId)) {
    // To know how the code works
    console.error('DEBUG: Missing mover. Maybe removed already?')
  }

  var mover = state.items[action.moverId]
  var nexter = state.items[mover.nexterId]

  var nextItems = Object.assign({}, state.items)
  delete nextItems[mover.id]
  delete nextItems[nexter.id]

  return Object.assign({}, state, {
    items: nextItems
  })
}
