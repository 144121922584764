
module.exports = function (csc) {
  if (!csc) {
    throw new Error('Invalid cell slot coordinate: ' + csc)
  }

  return {
    type: 'TAA_ADD_START',
    csc: csc
  }
}
