var updateCell = require('./updateCell')
var ttco = require('taataa-coords')

var trimAfterTiles = function (cellEvents) {
  // Return a subset of the given cellEvents which contains the events
  // upto the last TILES (including the last TILES).
  //

  // Find index of the last TILES event
  var i
  var lastTilesAt = -1
  for (i = 0; i < cellEvents.length; i += 1) {
    if (cellEvents[i].type === 'TILES') {
      lastTilesAt = i
    }
  }

  if (lastTilesAt === -1) {
    return []
  }

  return cellEvents.slice(0, lastTilesAt + 1)
}

module.exports = function (state, action) {
  // Use to feed in confirmed stream of cell events from cell node.
  // The stream must not have any clock gaps. Otherwise
  // the local cells do not represent the global truth.
  //
  // Action props:
  // - action.cell
  // - action.cellEvents
  //
  var cellId = action.cell.id
  var oldCell = state.cells[cellId]

  // Do not apply events after the latest TILES event.
  // This way we ensure that the tiles of the local cell are in sync.
  // See issue #10.
  var applicableCellEvents = trimAfterTiles(action.cellEvents)

  var upresult = updateCell(oldCell, applicableCellEvents)
  var cellsPart = {}
  cellsPart[action.cell.id] = upresult.cell

  // Cell events resolve pending items.
  // The resolution happens here.
  // Items are not removed immediately but marked as removable.
  // Removable items should remain visible but their behavior
  // must equal to slot behavior. To avoid flicker, they can only
  // be truly removed after all tiles are downloaded.
  var nextItems = {}
  Object.keys(state.items).forEach(function (itemId) {
    var item = state.items[itemId]

    // Resolve only items within the same cell.
    if (cellId === ttco.cellId(item.csc)) {
      if (item.cellEvent && item.cellEvent.clock <= upresult.cell.clock) {
        nextItems[itemId] = Object.assign({}, item, {
          state: 'removable',
          pending: false,
          removable: true
        })
        return
      }
    }
    // Else, keep the original item
    nextItems[itemId] = item
  })

  return Object.assign({}, state, {
    cells: Object.assign({}, state.cells, cellsPart),
    items: nextItems
  })

  // Remove dropped taas whose id is the same as an item in the events.
  // TODO somewhat hacky. Real solution is to skip events that have
  // already been computed locally.
  // itemsToRemove = action.cellEvents.filter(function (cev) {
  //   return cev.type === 'DROP'
  // }).map(function (dropEv) {
  //   // Select local items that match the cell event.
  //   var slotItemId = dropEv.item[0]
  //   if (state.items.hasOwnProperty(slotItemId)) {
  //     // Uncomment following line if you need to ensure that client
  //     // has received ok from the user server. Note that
  //     // cell update can be quicker than user server response.
  //     // if (state.items[slotItemId].state === 'dropped') {
  //     return slotItemId
  //   }
  //   // Select also adders that have received id
  //   var adderId = Object.keys(state.items).find(function (itemId) {
  //     // NOTE addedItemId is undefined for non-adder items.
  //     return state.items[itemId].addedItemId === slotItemId
  //   })
  //   if (adderId) { // .find returns undefined if not found
  //     return adderId
  //   }
  //   return null
  // }).filter(function (itemId) {
  //   // Keep only id strings.
  //   return itemId !== null
  // }).reduce(function (acc, itemId) {
  //   // Convert array to object
  //   acc[itemId] = true // dummy value because ttobj.substract does not care
  //   return acc
  // }, {})

  // HACK Remove pickers who receive PICK cell event.
  // This way we get a loading animation over the stack.
  // Better and correct way would be to compute the new cell state locally.
  // pickersToRemove = action.cellEvents.filter(function (cev) {
  //   return cev.type === 'PICK'
  // }).map(function (pickEv) {
  //   var slotItemId = pickEv.item[0]
  //   var pickerId = Object.keys(state.items).find(function (itemId) {
  //     var item = state.items[itemId]
  //     return (item.type === 'picker' && item.targetItemId === slotItemId)
  //   })
  //   return pickerId // undefined if not found
  // }).filter(function (itemId) {
  //   // Keep only id strings.
  //   return !!itemId
  // }).reduce(function (acc, itemId) {
  //   // Convert to object
  //   acc[itemId] = true // dummy value because ttobj.substract does not care
  //   return acc
  // }, {})

  // return Object.assign({}, state, {
  //   cells: Object.assign({}, state.cells, cellsPart),
  //   items: ttobj.subtract( // TODO write ttobj.join aka ttobj.union
  //     ttobj.subtract(state.items, pickersToRemove),
  //     itemsToRemove
  //   )
  // })
}
