// Function to compute a set of cell-slot coordinates that fill
// the given viewport around the given cell-slot coordinate.
//
var ttco = require('taataa-coords')

var locationToSlotList = function (loc, viewport) {
  // Select slots to fill the viewport.
  // Use the slot at the current location as a starting point and
  // compute the surrounding slot coordinates.
  //
  var ssc = ttco.ufCoordsToSpaceSlot(loc)
  var slotsW = Math.round(viewport.width / 256) + 3
  var halfW = Math.round(slotsW / 2)
  var slotsH = Math.round(viewport.height / 256) + 3
  var halfH = Math.round(slotsH / 2)

  var list = []
  var i, j, csc
  for (i = 0; i <= slotsW; i += 1) {
    for (j = 0; j <= slotsH; j += 1) {
      csc = ttco.spaceSlotToCellSlot({
        space: window.taataa.SPACEID,
        sz: ssc.sz,
        sx: ssc.sx + (i - halfW),
        sy: ssc.sy + (j - halfH)
      }, true) // true = prefer supercell
      list.push(csc)
    }
  }

  return list
}

// Interface

module.exports = function (location, viewport) {
  // Here we compute slots and cells that should be rendered and polled.

  // First, select slots that are visible or almost visible in the viewport.
  // SlotList is a list of CellSlotCoords.
  var viewportSlotList = locationToSlotList(location, viewport)

  // Convert slotList from array to key-value
  var necessarySlots = viewportSlotList.reduce(function (acc, slotCoords) {
    var k = ttco.cellSlotId(slotCoords)
    acc[k] = slotCoords // arbitrary value
    return acc
  }, {})

  return necessarySlots
}
