var storeItems = require('../actions/lib/storeItems')

module.exports = function (state, action) {
  // Create dropzoner store item
  //
  // Params:
  //   state
  //   action
  //     id
  //     csc
  //
  var newItems = {}
  var newItem = storeItems.dropzoner(action.id, action.csc)
  newItems[newItem.id] = newItem

  return Object.assign({}, state, {
    items: Object.assign({}, state.items, newItems)
  })
}
