
exports.getId = function (slot) {
  if (typeof slot === 'string') {
    // A tile
    return null
  }
  return slot[0]
}

exports.getType = function (slot) {
  if (typeof slot === 'string') {
    return 'tile'
  }
  var l = slot.length
  return l > 3 ? (l < 5 ? 'daa' : slot[4]) : 'taa'
}

exports.getHash = function (slot) {
  if (typeof slot === 'string') {
    return slot
  }
  return slot[1]
}

exports.getRotation = function (slot) {
  if (typeof slot === 'string') {
    return 0
  }
  return slot.length > 2 ? slot[2] : 0
}

exports.getChallenge = function (slot) {
  if (typeof slot === 'string') {
    return null
  }
  return slot.length > 3 ? slot[3] : null
}

exports.getState = function (slot) {
  if (typeof slot === 'string') {
    return null
  }
  return slot.length > 5 ? slot[5] : null
}
