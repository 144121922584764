// A store subscriber that decides which cells and slots to activate.
// The deactivation is a bit more complex and therefore implemented
// separately as slotDeactivator.
//
// NOTE The activator is basically a mapping from location and viewport
// to a set of active cells and active slots. Therefore it would
// seem to fit as a part of the reducer instead of being a subscriber.
// Still, due to the following reasons we implement it as a subscriber:
// - reducer is synchronous. More power over "when" with async activation.
// - easier to debug because explicit 'ACTIVATE_CELLS' etc.
//
var observe = require('taataa-observe')
var activate = require('./activate')

// Interface

module.exports = function (store) {
  observe(store, ['location', 'viewport'], function (state) {
    store.dispatch(activate())
  })
}
