var ttobj = require('taataa-objtools')
var cellFetch = require('./cellFetch')
var cellFetchSuccess = require('./cellFetchSuccess')
var cellPoll = require('./cellPoll')
var cellPollSuccess = require('./cellPollSuccess')
var taaAddSuccess = require('./taaAddSuccess')
var taaAddStart = require('./taaAddStart')
var taaDropzoneStart = require('./taaDropzoneStart')
var taaMoveCancel = require('./taaMoveCancel')
var taaMoveRequest = require('./taaMoveRequest')
var taaMoveSuccess = require('./taaMoveSuccess')
var taaMoveStart = require('./taaMoveStart')
var taaMoveFailed = require('./taaMoveFailed')
var cellEvents = require('./cellEvents')
var moveLocation = require('./moveLocation')

// Compute seconds from page init
var startTime = Math.round(Date.now() / 1000)

module.exports = function (state, action) {
  var item
  // NOTE A high-level design decision: define default state with createStore.
  // A convetion in the reducer is to check if the state is undefined and
  // populate it with a default state. However, we break the convention and
  // do not check if state is empty and do not provide a default state.
  // This is because our initial state is parametrized (e.g. location from
  // window.location hash) and we cannot pass those parameters with the first
  // action that is always @@redux/INIT.
  // Maybe if we used combineReducers, populating the state here would make
  // more sense.

  if (state.developer) {
    var nowTime = Math.round(Date.now() / 1000)
    console.log((nowTime - startTime) + 's', action, state)
  }

  switch (action.type) {
    // Navigation
    case 'MOVE_LOCATION':
      return moveLocation(state, action)
    case 'MOVE_ORIGIN':
      return state
    case 'VIEW_JUMP':
      return Object.assign({}, state, {
        jumpto: action.location
      })
    case 'VIEW_RESIZE':
      return Object.assign({}, state, {
        viewport: {
          width: action.width,
          height: action.height
        }
      })

    case 'CELL_FETCH':
      return cellFetch(state, action)
    case 'CELL_FETCH_SUCCESS':
      return cellFetchSuccess(state, action)
    case 'CELL_POLL':
      return cellPoll(state, action)
    case 'CELL_POLL_SUCCESS':
      return cellPollSuccess(state, action)
    case 'CELL_EVENTS':
      return cellEvents(state, action)

    case 'DEVELOPER_ONOFF':
      return Object.assign({}, state, { developer: !state.developer })

    case 'FETCHING_START':
      return Object.assign({}, state, {
        fetching: true
      })
    case 'FETCHING_STOP':
      return Object.assign({}, state, {
        fetching: false
      })

    case 'ACTIVATE_CELLS':
      return Object.assign({}, state, {
        activeCells: Object.assign({}, state.activeCells, action.cells)
      })
    case 'DEACTIVATE_CELLS':
      return Object.assign({}, state, {
        activeCells: ttobj.subtract(state.activeCells, action.cells)
      })

    case 'ACTIVATE_SLOTS':
      return Object.assign({}, state, {
        activeSlots: Object.assign({}, state.activeSlots, action.slots)
      })
    case 'DEACTIVATE_SLOTS':
      return Object.assign({}, state, {
        activeSlots: ttobj.subtract(state.activeSlots, action.slots)
      })

    case 'START_SLOT_APP':
      return state
    case 'STOP_SLOT_APP':
      return Object.assign({}, state, {
        items: ttobj.remove(state.items, action.itemId)
      })
    case 'DEACTIVATE_ITEMS':
      return Object.assign({}, state, {
        items: ttobj.subtract(state.items, action.items)
      })

    case 'POLLING_TICK':
      return Object.assign({}, state, {
        pollingTick: state.pollingTick + 1
      })
    case 'POLLING_START':
      return Object.assign({}, state, {
        polling: true
      })
    case 'POLLING_STOP':
      return Object.assign({}, state, {
        polling: false
      })

    case 'TAA_ADD_START':
      return taaAddStart(state, action)
    case 'TAA_ADD_REQUEST':
      item = Object.assign({}, state.items[action.adderId], {
        state: 'waitingResponse',
        pending: true
      })
      return Object.assign({}, state, {
        items: ttobj.insert(state.items, action.adderId, item)
      })
    case 'TAA_ADD_REQUEST_OK':
      return state
    case 'TAA_ADD_REQUEST_FAILED':
      return state
    case 'TAA_ADD_SUCCESS':
      return taaAddSuccess(state, action)
    case 'TAA_ADD_FAILED':
      return state

    case 'TAA_DROPZONE_START':
      return taaDropzoneStart(state, action)

    case 'TAA_MOVE_START':
      return taaMoveStart(state, action)
    case 'TAA_MOVE_CANCEL':
      return taaMoveCancel(state, action)
    case 'TAA_MOVE_REQUEST':
      return taaMoveRequest(state, action)
    case 'TAA_MOVE_REQUEST_OK':
      return state
    case 'TAA_MOVE_REQUEST_FAILED':
      return state
    case 'TAA_MOVE_SUCCESS':
      return taaMoveSuccess(state, action)
    case 'TAA_MOVE_FAILED':
      return taaMoveFailed(state, action)

    case 'USER_ID_FETCHING':
      return Object.assign({}, state, {
        userIdFetching: true
      })
    case 'USER_ID_ERROR':
      return Object.assign({}, state, {
        userIdFetching: false
      })
    case 'USER_ID_SET':
      return Object.assign({}, state, {
        userId: action.userId,
        userIdFetching: false
      })
    default:
      return state
    // TODO also owned item might need tasks and activation: taacoin
    // TODO also owned items need creation.
    // NOTE some discarded event names:
    // NOTE EXCITE_ITEM, TASK_ITEM, TRY_SOLVE_ITEM, UNSOLVE_ITEM
    // NOTE ACTIVATE_ITEM, OWN_ITEM
  }
}
