
module.exports = function (state, action) {
  var cellId = action.cell.id

  var cellsPart = {}
  cellsPart[cellId] = action.cell

  var olCellMeta = state.cellsMeta[cellId] // Inited in cellFetch
  var nuCellMeta = Object.assign({}, olCellMeta, {
    requestState: 'COMPLETE'
  })

  var metaPart = {}
  metaPart[cellId] = nuCellMeta

  return Object.assign({}, state, {
    cellsMeta: Object.assign({}, state.cellsMeta, metaPart),
    cells: Object.assign({}, state.cells, cellsPart)
  })
}
