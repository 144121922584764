var getCell = require('./getCell')
var generateSlotter = require('../actions/lib/storeItems').slotter

module.exports = function (state, action) {
  // Back-end has created the taa successfully and dropped it into cell.
  var oldAdder, newAdder
  var newItems = {}

  // Check if the cell already had the update (rare case).
  var cell = getCell(state, action.csc)
  if (cell.clock >= action.dropEvent.clock) {
    // The event already processed into the local cell state.
    // Cell update came faster than add resp.
    //
    // The adder can be marked to be removed when all tiles have downloaded.
    // Adder's loading animation must continue as long as the images
    // from the cell event finish downloading.
    //
    // There is no need to create a virtual slot because the real slot is
    // already available and its image may have finished downloading.
    //
    oldAdder = state.items[action.adderId]
    newAdder = Object.assign({}, oldAdder, {
      cellEvent: action.dropEvent,
      removable: true,
      pending: false,
      state: 'removable'
    })
    newItems[action.adderId] = newAdder

    return Object.assign({}, state, {
      items: Object.assign({}, state.items, newItems)
    })
  }

  // Cell did not receive the update yet (usual case).
  // Create a virtual slot that simulates the updated slot.
  // Virtual slot allows eg. moves.
  //
  // Mark adder for removal so that it can be removed when the virtual slot
  // is rendered and downloaded.
  //
  var slotter = generateSlotter(action.dropEvent, action.csc)
  oldAdder = state.items[action.adderId]
  newAdder = Object.assign({}, oldAdder, {
    cellEvent: action.dropEvent,
    pending: true,
    state: 'waitingPoll'
  })

  newItems[action.adderId] = newAdder
  newItems[slotter.id] = slotter

  return Object.assign({}, state, {
    items: Object.assign({}, state.items, newItems)
  })
}
