var switchFetching = function (fetching, dispatch) {
  if (fetching) {
    dispatch({
      type: 'FETCHING_STOP'
    })
  } else {
    dispatch({
      type: 'FETCHING_START'
    })
  }
}

var switchPolling = function (polling, dispatch) {
  if (polling) {
    dispatch({
      type: 'POLLING_STOP'
    })
  } else {
    dispatch({
      type: 'POLLING_START'
    })
  }
}

exports.render = function (dispatch, getState) {
  var state = getState()
  var debug = document.getElementById('debug')

  if (!state.developer) {
    // We are not in debug mode.
    if (debug) {
      // Remove the toolbar elements
      debug.parentNode.removeChild(debug)
    }
    return // exit
  }

  if (!debug) {
    // Init the elements
    //
    debug = document.createElement('div')
    debug.id = 'debug'
    document.body.appendChild(debug)

    debug.style.position = 'absolute'
    debug.style.top = '0'
    debug.style.left = '0'
    debug.style.padding = '0.62em'
    debug.style.color = 'red'
  }

  var html = '' +
    '<div><strong>Taataa Client ' + window.taataa.version + '</strong></div>' +
    '<div>Active cells: ' + Object.keys(state.activeCells).length + '</div>'

  html += '<div>Fetching: ' + state.fetching + ' '
  html += '<button id="debug-fetching-switch">' +
    (state.fetching ? 'Turn Off' : 'Turn On') +
    '</button></div>' +
    ''

  html += '<div>Polling: ' + state.polling + ' '
  html += '<button id="debug-polling-switch">' +
    (state.polling ? 'Turn Off' : 'Turn On') +
    '</button></div>' +
    ''

  debug.innerHTML = html

  var polbut = document.getElementById('debug-polling-switch')
  polbut.addEventListener('click', function () {
    switchPolling(state.polling, dispatch)
  })

  var fetbut = document.getElementById('debug-fetching-switch')
  fetbut.addEventListener('click', function () {
    switchFetching(state.fetching, dispatch)
  })
}
