var storeItems = require('../actions/lib/storeItems')

module.exports = function (state, action) {
  //
  var slot = action.slot
  var csc = action.sourceCsc

  // Create nexter first and attach its id to mover for easy cancellation.
  var newNexter = storeItems.nexter(csc)
  var newMover = storeItems.mover(slot, newNexter.id, csc)

  var newItems = {}
  newItems[newNexter.id] = newNexter
  newItems[newMover.id] = newMover

  return Object.assign({}, state, {
    items: Object.assign({}, state.items, newItems)
  })
}
