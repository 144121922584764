
module.exports = function (slotContent, sourceCsc) {
  // TODO request stack for next item.
  //
  return {
    type: 'TAA_MOVE_START',
    slot: slotContent,
    sourceCsc: sourceCsc
  }
}
