var getCell = require('./getCell')

module.exports = function (state, action) {
  // Back-end has picked the taa successfully and dropped it into a cell.
  var newMover, newNexter
  var newItems = {}

  var oldNexter = state.items[action.nexterId]
  var oldMover = state.items[action.moverId]

  if (!oldNexter) { throw new Error('Nexter not in items') }
  if (!oldMover) { throw new Error('Mover not in items') }

  // Check if the local cell already had the update (rare case).
  var sourceCell = getCell(state, action.sourceCsc)
  if (sourceCell.clock >= action.pickEvent.clock) {
    // The event is already processed into the local cell state.
    // Cell update came faster than move response.
    //
    // The nexter can be removed when all tiles have downloaded.
    // Nexter's loading animation must continue as long as the images
    // from the cell event finish downloading. Otherwise the user would
    // briefly see the old tile and think that something went wrong.
    //
    // There is no need to keep the virtual slot because the real slot is
    // already available and its image is at least downloading.
    //
    newNexter = Object.assign({}, oldNexter, {
      state: 'removable',
      pending: false,
      removable: true
    })
  } else {
    // The source cell did not receive the cell events yet (usual case).
    // The pick event carries info about the next item which allows
    // the illusion of a physical stack.
    newNexter = Object.assign({}, oldNexter, {
      state: 'waitingPoll',
      pending: true,
      cellEvent: action.pickEvent
    })
  }
  newItems[action.nexterId] = newNexter

  var targetCell = getCell(state, action.targetCsc)
  if (targetCell.clock >= action.dropEvent.clock) {
    // The drop event is already processed into the local cell state.
    // Polled cell events came faster than move response.
    //
    // The mover can be removed when all tiles have downloaded.
    // Mover's tile must be visible as long as the images
    // from the polled cell event are downloading. Otherwise the user would
    // briefly see the old tile and think that something went wrong.
    //
    newMover = Object.assign({}, oldMover, {
      state: 'removable',
      pending: false,
      removable: true
    })
  } else {
    // The target cell did not receive the polled cell event yet (usual case).
    //
    newMover = Object.assign({}, oldMover, {
      state: 'waitingPoll',
      pending: true,
      cellEvent: action.dropEvent
    })
  }
  newItems[action.moverId] = newMover

  // Update
  return Object.assign({}, state, {
    items: Object.assign({}, state.items, newItems)
  })
}
