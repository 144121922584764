// Define the name of taataa's default tiles and provide a method
// to pick correct for a given zoom level.
//
// NOTE: The filenames are defined in this module but we cannot give
// the actual image files here because the module is intented for both
// server-side and client-side use. If images were specified here,
// the interface should provide a path to the files. However, no such a path
// could exist in the client's browser environment.
// Therefore, the user of this module must come up with the image files
// and paths to them.
//
var DEFAULT_TILES = [
  '1x1.png',
  '2x2.png',
  '4x4.png',
  'black.jpg'
]
exports.DEFAULT_TILES = DEFAULT_TILES

exports.pickDefaultTile = function (csc) {
  // Pick one from filenames so that n:th z has n:th filename.
  //
  // Params:
  //   csc: cell-slot coordinate for to pick the tile.
  //
  // Returns
  //   1st element of the tiles array at z=36, 2nd at z=35 and so on...
  //   Returns first and last element if out of range
  //
  var sz = csc.cz * 4 + csc.z
  var i = 36 - sz
  if (i >= DEFAULT_TILES.length || i < 0) {
    // Pick last if layer above or below the defined tile set.
    return DEFAULT_TILES[DEFAULT_TILES.length - 1]
  }
  return DEFAULT_TILES[i]
}
