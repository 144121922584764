var ttco = require('taataa-coords')
var tapspace = require('tapspace')
var actions = require('../../actions')

var getFileOrUrl = function (dataTransfer, cb) {
  var i, item

  if (dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (i = 0; i < dataTransfer.items.length; i += 1) {
      item = dataTransfer.items[i]
      // If dropped items aren't files, reject them
      if (item.kind === 'file') {
        return cb(null, item.getAsFile())
      }

      if (item.type === 'text/uri-list') {
        item.getAsString(function (s) {
          if (s.substring(0, 4) === 'http') {
            return cb(null, s)
          }
          console.log(s) // DEBUG
          return cb(new Error('No valid URL found: ' + s))
        })

        return
      }

      // DEBUG
      console.log('index', i)
      console.log('kind', item.kind)
      console.log('type', item.type)
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (i = 0; i < dataTransfer.files.length; i += 1) {
      return cb(null, dataTransfer.files[i])
    }
  }

  // No items or files
  return cb(new Error('No file or URL dropped'))
}

module.exports = function (view, store) {
  // Drop files to create taas.
  //
  var el = document.getElementById('space')

  el.addEventListener('drop', function (ev) {
    // File(s) dropped

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault()

    // If view above from movable zone, do nothing.
    // If we are one the bottommost cell layer, allow file drop.
    // Similar behavior with move
    var state = store.getState()
    if (state.location.uz <= 33) {
      return
    }

    // Find drop location in space.
    // Find target csc on z=4 cz=8.
    // assert uz > 33
    var midVec = new tapspace.geom.Vector(ev.clientX, ev.clientY)
    var mid = new tapspace.geom.IVector(midVec, view)
    var gt = view.getGlobalTransform()
    var uf = ttco.viewToUfCoords(state.origin, {
      mid: mid.toSpace(),
      scale: gt.getScale(),
      rotation: gt.getRotation()
    })

    uf.uz = 36 // The item at ground level.
    var targetCsc = ttco.ufCoordsToCellSlot(uf)
    targetCsc.space = window.taataa.SPACEID // TODO read from slot

    // TODO Reserve a taacoin. Stop if no coins available.

    // Access the dropped files.
    getFileOrUrl(ev.dataTransfer, function (err, fileOrUrl) {
      if (err) {
        console.error(err) // DEBUG
        return // do nothing
      }

      // Create dropzoner. A kind of a loader tile.
      // HACK create ID here so we can feed it to taaAddRequest.
      var zonerId = 'dropzoner' + Math.random().toString().substring(2)
      store.dispatch(actions.taaDropzoneStart(zonerId, targetCsc))

      // Upload the file. taaAddRequest handels the rest.
      store.dispatch(actions.taaAddRequest(zonerId, fileOrUrl, targetCsc))
    })
  })

  el.addEventListener('dragover', function (ev) {
    // File(s) in drop zone. Fired multiple times per second.

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault()
  })
}
