// Mover. A virtual item that exists to move a taa.

var altImageClip = require('../lib/altImageClip')
var effects = require('../lib/effects')
var ttco = require('taataa-coords')
var ttslot = require('taataa-slot')
var tapspace = require('tapspace')
var getTileDesc = require('./getTileDesc')
var makeDraggable = require('./makeDraggable')

exports.create = function (opts) {
  // Create item
  //
  // Params:
  //  opts:
  //    storeItem
  //    view
  //    store: a Redux store
  //    itemGroup
  //
  var storeItem = opts.storeItem
  var view = opts.view
  var store = opts.store
  var itemGroup = opts.itemGroup
  var slotGroup = opts.slotGroup

  var state = store.getState()
  var tileDesc = getTileDesc(
    ttslot.getId(storeItem.slot),
    storeItem.csc,
    state.cells,
    state.items,
    slotGroup
  )

  var clipHtml = altImageClip.generateHtml(tileDesc.clipSrc, tileDesc.clipRect)
  var itemHtml = '<div>' + clipHtml + '</div>' // 2nd container for effects

  var sitem = new tapspace.SpaceHTML(itemHtml, itemGroup)
  sitem.setSize(256, 256)

  var cont = view.getElementBySpaceItem(sitem)
  var effectCont = cont.firstChild
  sitem.effectContainer = effectCont
  sitem.endEffect = effects.none()

  // Class the container for easier styling and debugging.
  cont.className += ' tt-item tt-mover'

  // Reference to item
  // NOTE we cannot just 'sitem.item = item' because the functional approach.
  sitem.moverId = storeItem.id

  // Reference to original position
  sitem.sourceCsc = storeItem.csc

  // Position
  var orig = store.getState().origin
  var rect = ttco.cellSlotToViewRect(orig, storeItem.csc)
  sitem.translateScale([
    sitem.atNW(),
    sitem.atSE()
  ], [
    itemGroup.at(rect.x0, rect.y0),
    itemGroup.at(rect.x1, rect.y1)
  ])

  // Load the full tile and then replace the low-res version.
  tapspace.preload(tileDesc.fullSrc, function (err, img) {
    if (err) {
      console.error(err)
      return
    }

    // Prevent issue #4
    img.draggable = false

    // Ditch the clip HTML
    effectCont.replaceChild(img, effectCont.firstChild)
  })

  // Touch capabilities
  sitem.touch = new tapspace.Touchable(view, sitem)

  // State specific init
  if (storeItem.state === 'picked') {
    // Display animation to push user to do something.
    sitem.endEffect = effects.loadingCcw(sitem.effectContainer)

    // Allow drop after a short interval to prevent
    // double clicks seemingly do nothing.
    // 500ms is the default double-click timeframe in Windows.
    // Source: https://ux.stackexchange.com/a/40366/33132
    setTimeout(function () {
      sitem.endEffect()
      sitem.endEffect = effects.glowMove(sitem.effectContainer)
      makeDraggable(sitem, store.dispatch, store.getState().origin)
    }, 500)
  }

  return sitem
}

exports.update = function (opts) {
  // Detect change and act accordingly.
  //
  // Params:
  //  opts:
  //    spaceItem
  //    store: a Redux store
  //    newStoreItem
  //    oldStoreItem
  //
  var sitem = opts.spaceItem
  var ol = opts.oldStoreItem
  var nu = opts.newStoreItem

  if (ol.state === nu.state) { return sitem }

  if (nu.state === 'waitingResponse') {
    // Display loading animation on top of task and solution.
    sitem.endEffect()
    sitem.endEffect = effects.loading(sitem.effectContainer)
    // TODO remove tasks
    return sitem
  }

  if (nu.state === 'waitingPoll') {
    // TODO Play a success effect
    // End all effects.
    sitem.endEffect()
    sitem.endEffect = effects.none()
    return sitem
  }

  if (nu.state === 'removable') {
    return sitem
  }

  throw new Error('Unexpected state transition: ' + ol + ' to ' + nu)
}

exports.remove = function (opts) {
  // Remove item's Tapspace rendering.
  //
  // Params:
  //   opts:
  //     spaceItem: SpaceItem to remove
  //
  var sitem = opts.spaceItem
  sitem.touch.stop()
  sitem.remove()
}
