var ttco = require('taataa-coords')
var tapspace = require('tapspace')
var cellmodel = require('taataa-cellmodel')
var isImageOk = require('taataa-isimageok')

var removePrevious = function (sitem) {
  // Remove possible previous tiles recursively
  var p = sitem.previousTile
  var q // temp swap variable
  while (p) {
    // NOTE p might already been removed
    // but simg.remove() call just ensures it.
    p.remove()
    q = p.previousTile
    delete p.previousTile // remove references
    p = q
  }
}

exports.create = function (slot, opts) {
  // Create tile
  //
  // Params:
  //  slot
  //  opts:
  //    csc
  //    origin
  //    tileGroup
  //    view
  //
  var csc = opts.csc
  var origin = opts.origin
  var tileGroup = opts.tileGroup
  var view = opts.view

  var cellId = ttco.cellId(csc)
  var src = cellmodel.getSlotTileUrl(cellId, csc, slot)

  var simg = new tapspace.SpaceImage({
    src: src,
    width: 256,
    height: 256
  }, tileGroup)

  // HACK Should be implemented in Tapspace
  // See https://github.com/taataa/tapspace/issues/135
  var el = view.getElementBySpaceItem(simg)
  el.draggable = false

  // Position
  var r = ttco.cellSlotToViewRect(origin, csc)
  simg.translateScale([
    simg.atNW(),
    simg.atSE()
  ], [
    tileGroup.at(r.x0, r.y0),
    tileGroup.at(r.x1, r.y1)
  ])

  // Keep references to slot.
  // This mechanism allows us to detect if slot is changed.
  simg.slot = slot
  simg.csc = csc

  return simg
}

exports.update = function (simg, opts) {
  // Detect change and act accordingly.
  //
  // Params:
  //  simg: SpaceImage
  //  opts:
  //    view
  //    newSlot
  //    oldSlot
  //
  var nu = opts.newSlot
  var ol = opts.oldSlot

  if (ol === nu) {
    return simg
  }

  var cellId = ttco.cellId(simg.csc)
  var newSrc = cellmodel.getSlotTileUrl(cellId, simg.csc, nu)

  var nusimg = new tapspace.SpaceImage({
    src: newSrc,
    width: 256,
    height: 256
  }, simg.getParent())

  // Same position
  nusimg.setLocalTransform(simg.getLocalTransform())

  // Same references
  nusimg.slot = nu
  nusimg.csc = simg.csc

  // Remove old after new is loaded.
  // Note that a second update can come before first is complete.
  // Therefore we form a chain of previousTile pointers and
  // remove the previous recursively.
  nusimg.previousTile = simg
  setTimeout(function tick () {
    if (nusimg.isRoot()) {
      // nusimg is already removed, we can stop the recursion.
      return
    }

    var nuimg = opts.view.getElementBySpaceItem(nusimg)

    // Test if the new image is complete.
    if (isImageOk(nuimg)) {
      // Remove possible previous tiles recursively
      removePrevious(nusimg)
      // All previous tiles are now removed.
      // Only the new tile remains.
    } else {
      // Wait some more for the new image to complete.
      setTimeout(tick, 2000)
    }
  }, 2000)

  return nusimg
}

exports.remove = function (simg) {
  // Remove rendered item.
  //
  // Params:
  //   simg: SpaceImage to remove
  //
  removePrevious(simg)
  simg.remove()
}
