var ttcoords = require('taataa-coords')
var actions = require('../../actions')

module.exports = function (view, store) {
  // Keyboard bindings
  //
  // NOTE In Redux-paradigm the view should react to location change
  // and not the other way around. However, here we break the paradigm
  // because animations, which is probably bad decision.

  // Inform store when the view moves.
  var onLocationChange = function () {
    var origin = store.getState().origin
    var gt = view.getGlobalTransform()

    var ufloc = ttcoords.viewToUfCoords(origin, {
      mid: view.atMid().toSpace(),
      scale: gt.getScale(),
      rotation: gt.getRotation()
    })

    store.dispatch(actions.moveLocation(ufloc))
  }

  document.addEventListener('keydown', function (e) {
    e = e || window.event

    switch (e.keyCode) {
      case 37: // arrow left
      case 65: // a
        if (e.shiftKey || e.altKey) {
          view.rotate(view.atMid(), Math.PI / 12)
        } else {
          view.translate(view.at(256, 0), view.at(0, 0))
        }
        onLocationChange()
        break
      case 38: // arrow up
      case 87: // w
        view.translate(view.at(0, 256), view.at(0, 0))
        onLocationChange()
        break
      case 39: // arrow right
      case 68: // d
        if (e.shiftKey || e.altKey) {
          view.rotate(view.atMid(), -Math.PI / 12)
        } else {
          view.translate(view.at(0, 0), view.at(256, 0))
        }
        onLocationChange()
        break
      case 40: // arrow down
      case 83: // s
        view.translate(view.at(0, 0), view.at(0, 256))
        onLocationChange()
        break
      case 90: // z ~ zoom in
      case 187: // +
      case 188: // ,
        view.scale(view.atMid(), 0.625)
        onLocationChange()
        break
      case 88: // x ~ eXpand
      case 189: // -
      case 190: // .
        view.scale(view.atMid(), 1.6)
        onLocationChange()
        break
    }
  })
}
