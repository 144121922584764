var cellApi = require('../../backend/cellApi.js')

exports.poll = function (cellId) {
  // A redux thunk.
  return function (dispatch, getState) {
    var state = getState()
    var cell = state.cells[cellId]
    var cellClock = cell.clock

    dispatch({
      type: 'CELL_POLL',
      cellId: cellId,
      cellClock: cellClock
    })

    cellApi.fetchCellEvents(cellId, cellClock, function (err, cellEvents) {
      if (err) {
        return dispatch({ type: 'CELL_POLL_FAILED', error: err })
      }

      dispatch({
        type: 'CELL_POLL_SUCCESS',
        cellId: cellId,
        cellClock: cellClock,
        cellEvents: cellEvents
      })

      if (cellEvents.length > 0) {
        dispatch({
          type: 'CELL_EVENTS',
          cell: cell, // id is not enough: we might need state clocks of evs
          cellEvents: cellEvents
        })
      }
    })
  }
}

exports.fetch = function (cellId) {
  // A redux thunk.
  return function (dispatch, getState) {
    dispatch({ type: 'CELL_FETCH', cellId: cellId })

    cellApi.fetchCell(cellId, function (err, cell) {
      if (err) {
        return dispatch({ type: 'CELL_FETCH_FAILED', error: err })
      }

      dispatch({ type: 'CELL_FETCH_SUCCESS', cell: cell })
    })
  }
}
