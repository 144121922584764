var tapspace = require('tapspace')
var ttco = require('taataa-coords')
var actions = require('../../actions')

module.exports = function (view, store) {
  // Make view touch-transformable
  var tView = new tapspace.Touchable(view, view)
  tView.start({
    translate: true,
    scale: true,
    rotate: true,
    tap: true
  })

  // Allow zooming with mouse wheel
  var wView = new tapspace.Wheelable(view, view)
  wView.start({
    scale: true
  })

  // Force discrete rotation steps
  var viewGrid = new tapspace.geom.IGrid({
    rotateStep: Math.PI / 6
  })
  tView.on('gestureend', function () {
    view.snap(view.atMid(), viewGrid)
  })

  // Inform store when the view moves.
  var onLocationChange = function () {
    var origin = store.getState().origin
    var gt = view.getGlobalTransform()

    var ufloc = ttco.viewToUfCoords(origin, {
      mid: view.atMid().toSpace(),
      scale: gt.getScale(),
      rotation: gt.getRotation()
    })

    store.dispatch(actions.moveLocation(ufloc))
  }
  tView.on('gestureend', onLocationChange)
  wView.on('gestureend', onLocationChange)

  tView.on('tap', function (ev) {
    // TODO bind to items, otherwise multi-user fails.
    // TODO however, we cannot create all individual items because tile pyramid
    // TODO solve by using all ev.points, not mean. Group all that touch same.
    var csc, gt, uf
    var mid = tapspace.geom.IVector.mean(ev.points)
    var state = store.getState()

    // If we are one the bottommost cell layer, activate the item.
    // If we are on a higher cell layer, we do not even know the item.
    if (state.location.uz > 33) {
      gt = view.getGlobalTransform()
      uf = ttco.viewToUfCoords(state.origin, {
        mid: mid.toSpace(),
        scale: gt.getScale(),
        rotation: gt.getRotation()
      })

      uf.uz = 36 // The item at ground level.
      csc = ttco.ufCoordsToCellSlot(uf)
      csc.space = window.taataa.SPACEID // TODO read from slot

      store.dispatch(actions.startSlotApp(csc))
    } else {
      view.scale(mid, 0.8)
      onLocationChange()
    }
  })
}
