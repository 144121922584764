var ttco = require('taataa-coords')
var ttobj = require('taataa-objtools')
var computeNeighborhood = require('../computeNeighborhood')

// Shorten
var subtract = ttobj.subtract

module.exports = function () {
  // A thunk.
  //
  return function (dispatch, getState) {
    var state = getState()
    var slotBuffer = computeNeighborhood(state.location, state.viewport)

    // Find out the set of cells that must be active for the slots.
    var cellBuffer = Object.keys(slotBuffer).reduce(function (acc, csid) {
      var csc = slotBuffer[csid]
      var cellId = ttco.cellId(csc)
      acc[cellId] = true // arbitrary value
      return acc
    }, {})

    var newSlots = subtract(slotBuffer, state.activeSlots)
    if (Object.keys(newSlots).length > 0) {
      dispatch({
        type: 'ACTIVATE_SLOTS',
        slots: newSlots
      })
    }

    var newCells = subtract(cellBuffer, state.activeCells)
    if (Object.keys(newCells).length > 0) {
      dispatch({
        type: 'ACTIVATE_CELLS',
        cells: newCells
      })
    }
  }
}
