var ttslot = require('taataa-slot')
var userApi = require('../backend/userApi')
var fetchUserId = require('./fetchUserId')

module.exports = function (moverId, sourceCsc, targetCsc) {
  // A thunk.
  //
  return function (dispatch, getState) {
    dispatch(fetchUserId()).then(function (userId) {
      //
      var state = getState()
      var mover = state.items[moverId]
      var nexterId = mover.nexterId
      var movedId = ttslot.getId(mover.slot)

      dispatch({
        type: 'TAA_MOVE_REQUEST',
        moverId: moverId,
        nexterId: nexterId,
        movedId: movedId,
        sourceCsc: sourceCsc,
        targetCsc: targetCsc
      })

      var respPromise = userApi.moveTaa(userId, movedId, sourceCsc, targetCsc)

      respPromise.then(function (cellEvents) {
        dispatch({
          type: 'TAA_MOVE_REQUEST_OK',
          moverId: moverId,
          nexterId: nexterId,
          movedId: movedId
        })

        if (typeof cellEvents === 'string') {
          dispatch({
            type: 'TAA_MOVE_FAILED',
            moverId: moverId,
            nexterId: nexterId,
            movedId: movedId,
            code: cellEvents
          })
        } else {
          dispatch({
            type: 'TAA_MOVE_SUCCESS',
            moverId: moverId,
            nexterId: nexterId,
            movedId: movedId,
            pickEvent: cellEvents[0],
            dropEvent: cellEvents[1],
            sourceCsc: sourceCsc,
            targetCsc: targetCsc
          })
        }
      }, function (err) {
        dispatch({
          type: 'TAA_MAKE_REQUEST_FAILED',
          moverId: moverId,
          nexterId: nexterId,
          movedId: movedId,
          error: err
        })
      })
    })
  }
}
