// Define behavior on viewport resize

module.exports = function (view, store) {
  // Make viewport size a part of state for slot selector.
  window.addEventListener('resize', function (ev) {
    view.refreshSize()
    var s = view.getSize()
    store.dispatch({ type: 'VIEW_RESIZE', width: s.width, height: s.height })
  })
}
