var altImageClip = require('../lib/altImageClip')
var ttco = require('taataa-coords')
var ttslot = require('taataa-slot')
var ttobj = require('taataa-objtools')
var cellmodel = require('taataa-cellmodel')

module.exports = function (itemId, csc, storeCells, storeItems, slotGroup) {
  // Image for the taa can be found from many places.
  // This pure fn encapsules the logic to pick the image.
  //
  // Precondition:
  //   item tile image is hosted on the cell.
  //
  // Params:
  //   itemId
  //   csc
  //   ...
  //
  // Return object:
  //   clipSrc: temporary image url (locally available)
  //   clipRect: temporary image clipping rectangle
  //   fullSrc: final image url
  //

  // Is item or image available in cell?
  var cellId = ttco.cellId(csc)
  var cell = storeCells[cellId]
  if (!cell) { // DEBUG
    throw new Error('Missing cell ' + cellId)
  }
  var cm = cellmodel(cell)
  var slot = cm.getSlot(csc)
  var slotType = ttslot.getType(slot)
  var isItemTopmost = (slotType !== 'tile' && ttslot.getId(slot) === itemId)

  // The full-size image might not be immediately available but
  // we still need an image asap for smooth experience.
  // We use the merged images if available.
  var altTile = null
  if (isItemTopmost) {
    altTile = altImageClip.find(slotGroup, csc)
  } else {
    // Try to find the slot from mover.
    var mover = ttobj.find(storeItems, function (item) {
      return item.type === 'mover' && ttco.cellSlotEqual(item.csc, csc)
    })
    if (mover) {
      slot = mover.slot
    }
  }

  // Full size 256x256 tile is available on the cell server.
  var tileHash = ttslot.getHash(slot)
  var tileSrc = cm.getTileUrl(tileHash)

  if (isItemTopmost && altTile !== null) {
    // Use merged image before full-size image gets loaded.
    return {
      clipSrc: altTile.src,
      clipRect: altTile.rect,
      fullSrc: tileSrc
    }
  }

  // Use the full image from the beginning
  // TODO use loading animation or some other local image for instant feel.
  return {
    clipSrc: tileSrc,
    clipRect: { x0: 0, y0: 0, x1: 256, y1: 256 },
    fullSrc: tileSrc
  }
}
