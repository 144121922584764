var ttcoords = require('taataa-coords')
var ttobj = require('taataa-objtools')
var ttslot = require('taataa-slot')
var ttcell = require('taataa-cell')

var taaAddStart = require('./taaAddStart')
var taaMoveStart = require('./taaMoveStart')
var deactivateItems = require('./deactivateItems')

module.exports = function (csc) {
  // An activation of a slot.
  //
  // A thunk.
  //
  // Params:
  //   csc: start slot app of csc
  //
  return function (dispatch, getState) {
    var state = getState()
    var cellId = ttcoords.cellId(csc)

    dispatch({
      type: 'START_SLOT_APP',
      csc: csc
    })

    // Ensure cell is available.
    if (!state.activeCells.hasOwnProperty(cellId)) {
      return
    }
    if (!state.cells.hasOwnProperty(cellId)) {
      return
    }

    // Deactivate just initialized movers and adders, if there is any.
    var offItems = ttobj.filter(state.items, function (item) {
      return !item.pending && !item.removable
    })
    if (Object.keys(offItems).length > 0) {
      return dispatch(deactivateItems(offItems))
    }

    // Get the slot content.
    var slot

    // Find the topmost virtual item. Null if there is none.
    var piledItems = ttobj.filter(state.items, function (item) {
      return ttcoords.cellSlotEqual(item.csc, csc)
    })
    var topItem = ttobj.max(piledItems, function (item) {
      return item.creationTime
    })

    if (topItem) {
      switch (topItem.type) {
        case 'adder':
          // Do nothing.
          return
        case 'mover':
          if (topItem.state === 'picked') {
            // Just picked. Do nothing extra.
            return
          } // else
          slot = topItem.slot
          break
        case 'slotter':
          slot = topItem.cellEvent.item
          break
        case 'nexter':
          if (topItem.cellEvent === null) {
            // Still loading. Do nothing.
            return
          } // else
          slot = topItem.cellEvent.nextItem
          break
        default:
          // Unknown top item. Do nothing.
          return
      }
    } else {
      // Slot from global cell
      slot = ttcell.getSlot(state.cells[cellId], csc)
    }

    var slotType = ttslot.getType(slot)

    if (slotType === 'tile') {
      // Empty slot or tile.
      // NOTE: [] is not a valid slotItem cuz slots cannot represent a stack

      // NOTE More than once there has been a tile instead of an item
      // on the ground layer. TODO a global database integrity check.
      // NOTE allow item addition on a tile. => self healing system.
      return dispatch(taaAddStart(csc))
    }

    // This is the place where we decide how the item reacts.
    // Note that a sigle item can have multiple possible ways to react.
    // E.g. video taa can play or be moved.
    if (slotType === 'taa') {
      return dispatch(taaMoveStart(slot, csc))
    }

    // Unknown type, do nothing
    console.error('Unknown item type ' + slotType)
  }
}
