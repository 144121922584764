// A Redux pattern
//
module.exports = function (store, keys, handler) {
  // Call handler if one of the keyed values in the store change.

  // Init memery
  var mem = keys.reduce(function (acc, key) {
    // Use undefined instead of null so that change to undefined
    // does not cause observation.
    acc[key] = undefined
    return acc
  }, {})

  store.subscribe(function () {
    var state = store.getState()

    var changed = false
    var i, k
    for (i = 0; i < keys.length; i += 1) {
      k = keys[i]
      if (mem[k] !== state[k]) {
        changed = true
        mem[k] = state[k]
      }
    }

    if (changed) {
      handler(state)
    } // else do not call
  })
}
