var usermapper = require('taataa-usermapper')

var ENV = typeof window === 'undefined' ? process.env.NODE_ENV
  : window.taataa.NODE_ENV
var PROTOCOL = ENV === 'development' ? 'http' : 'https'

// Class

var UserModel = function (userObj) {
  this.userServerInfo = usermapper(userObj.id)
  this.user = userObj
}

var proto = UserModel.prototype

proto.getUserUrl = function () {
  return PROTOCOL + '://' + this.userServerInfo.userHost +
    '/user/' + this.user.id
}

proto.getTileUrl = function (hash) {
  return PROTOCOL + '://' + this.userServerInfo.userHost +
    '/static/user/' + this.user.id + '/' + hash + '.jpg'
}

proto.getItemTileUrl = function (itemId) {
  var item
  if (this.user.items.hasOwnProperty(itemId)) {
    item = this.user.items[itemId]
    return this.getTileUrl(item.imageHash)
  }
  return null // no item
}

// Interface

exports.create = function (userObj) {
  return new UserModel(userObj)
}

exports.getUserUrl = function (userId) {
  var userServerInfo = usermapper(userId)
  return PROTOCOL + '://' + userServerInfo.userHost +
    '/user/' + userId
}

exports.getTileUrl = function (userId, hash) {
  var info = usermapper(userId)
  return PROTOCOL + '://' + info.userHost +
    '/static/user/' + userId + '/' + hash + '.jpg'
}

exports.getRandomUserHostUrl = function () {
  // Picks one user server and returns a URL to it.
  // Used to connect a user server the first time to generate a ID.
  //
  // TODO should the sampling prob distribution consider the slot ratio?
  //
  var hosts = usermapper.userHosts()
  var i = Math.floor(Math.random() * hosts.length)
  return PROTOCOL + '://' + hosts[i]
}
