// Defines rendering active items and their garbage collecting.
//
var tileItem = require('./spaceItems/tile')
var ttco = require('taataa-coords')
var ttobj = require('taataa-objtools')
var observe = require('taataa-observe')

module.exports = function (view, store, tileGroup) {
  // Keep track of rendered images.
  // Another way would be to linearly search the items in slotGroup.
  var renderedTiles = {}

  var createTile = function (args, csid) {
    var newTile = tileItem.create(args.slot, {
      csc: args.csc,
      origin: args.origin,
      tileGroup: tileGroup,
      view: view
    })
    renderedTiles[csid] = newTile
  }

  var updateTile = function (args, csid) {
    var simg = renderedTiles[csid]
    var newSlot = args.slot
    var oldSlot = simg.slot

    if (newSlot === oldSlot) {
      return // Filter. Item not changed, no need to update.
    }

    var newSimg = tileItem.update(simg, {
      view: view,
      oldSlot: oldSlot,
      newSlot: newSlot
    })
    // NOTE: .update might return the same object.

    renderedTiles[csid] = newSimg
  }

  var removeTile = function (spaceImage, csid) {
    tileItem.remove(spaceImage)
    delete renderedTiles[csid]
  }

  observe(store, ['activeSlots', 'cells', 'origin'], function (state) {
    // Here we create, update, and remove SpaceImages that
    // represent the visible top of slots.

    // We can skip the slots if their cell is not yet available.
    // The skipped slots will be rendered later, when cells change.
    // At the same time with the skip test, we convert state.activeSlots to
    // a more useful form: csid -> args
    var tiles = Object.keys(state.activeSlots).reduce(function (acc, csid) {
      var csc = state.activeSlots[csid]
      var cellId = ttco.cellId(csc)
      if (state.cells.hasOwnProperty(cellId)) {
        // Cell is available
        var cell = state.cells[cellId]
        var slot = cell.layers[csc.z][csc.x][csc.y]
        acc[csid] = {
          slot: slot,
          csc: csc,
          origin: state.origin
        }
      }
      return acc
    }, {})

    // Now we have the slots which we can know.
    // We divide the slots to three mutually exclusive sets.
    // Each set has a dedicated handler function.
    var tilesToCreate = ttobj.subtract(tiles, renderedTiles)
    var tilesToUpdate = ttobj.cut(tiles, renderedTiles)
    var tilesToRemove = ttobj.subtract(renderedTiles, tiles)
    // NOTE: ttobj.substract copies the value from the first param.
    // NOTE: ttobj.cut copies the value from the first param.

    // Update changed items.
    ttobj.each(tilesToUpdate, updateTile)
    // Create new items
    ttobj.each(tilesToCreate, createTile)
    // Collect garbage
    ttobj.each(tilesToRemove, removeTile)

    // DEBUG Show slot content as a helpful title tooltip.
    if (state.developer) {
      ttobj.each(renderedTiles, function (spaceImg, csid) {
        var el = view.getElementBySpaceItem(spaceImg)
        el.title = JSON.stringify(spaceImg.slot) + '\nat ' + csid
      })
    }
  })

  // DEBUG Show slot content as a helpful title tooltip.
  // Render or hide the tooltips if developer mode changes.
  observe(store, ['developer'], function (state) {
    ttobj.each(renderedTiles, function (spaceImg, csid) {
      var el = view.getElementBySpaceItem(spaceImg)

      if (state.developer) {
        el.title = JSON.stringify(spaceImg.slot) + '\nat ' + csid
      } else {
        el.removeAttribute('title')
      }
    })
  })
}
