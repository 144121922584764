//
// viewport moves
// select slots and items that must stay rendered.
//
// slotRenderer reacts to the list of active slots
// itemRenderer reacts to the list of active items
//
// slotSelector reacts to the viewport
//   remove slots that are outside but not under active items.
//   - items might be pending for poll results.
//   - to prevent memory leak.
//   remove slots that are from another zoom level
//   - to speed things up
//   do not remove slots until all (or just needed) images finish downloading
//   - otherwise flickers
//
// itemSelector
//   remove removable items after all images finish downloading
//   - otherwise flickers
//   remove items that are too far in space or time
//   - to prevent memory leak.
//
// Currently a bad situation:
// - slotselector cares about viewport and items
// - slotselector does not care about image downloading progress
// - slotselector creates new slots
// - slotrenderer cares about image downloading progress
// - items are not removed at all
//
// Approach
// - three sets:
//   - active slots (aka rendered, visible, needed slots)
//   - active items (rendered, needed items)
//   - active cells (cells that must be polled for changes)
//
// Problem: it is a bit problematic to follow which images are loaded.
// Solution: attach data-slotid to the slot image elements

var areTilesComplete = require('./areTilesComplete')
var computeNeighborhood = require('../computeNeighborhood')
var ttco = require('taataa-coords')
var ttobj = require('taataa-objtools')

// Shorten
var subtract = ttobj.subtract

module.exports = function () {
  // A thunk.
  //
  return function (dispatch, getState) {
    var state = getState()
    var slotBuffer = computeNeighborhood(state.location, state.viewport)

    if (!areTilesComplete(slotBuffer)) {
      return
    }

    var cellHood = Object.keys(slotBuffer).reduce(function (acc, csid) {
      var csc = slotBuffer[csid]
      var cellId = ttco.cellId(csc)
      acc[cellId] = true
      return acc
    }, {})

    // Remove all items that are marked as removable or
    // that are not pending and outside the slot buffer.
    var nextItems = Object.keys(state.items).filter(function (itemId) {
      var item = state.items[itemId]
      var cellId = ttco.cellId(item.csc)
      var itemInHood = cellHood.hasOwnProperty(cellId)
      // Keep the item if
      // - item is not marked for removal AND
      // - is pending OR
      // - is in the viewport (neighborhood).
      return !item.removable && (itemInHood || item.pending)
    }).reduce(function (acc, itemId) {
      // Build next items object.
      acc[itemId] = state.items[itemId]
      return acc
    }, {})

    var nextActiveSlots = slotBuffer

    // Keep the cells with pending items active even when there is no
    // such slots in the buffer. Why? Because the pending items need
    // to receive polled cell events before we can resolve them.
    var itemSlots = Object.keys(nextItems).reduce(function (acc, itemId) {
      var csc = nextItems[itemId].csc
      var csid = ttco.cellSlotId(csc)
      acc[csid] = csc
      return acc
    }, {})

    // Merge the active slot buffer and pending slots to
    // find out the set of active cells.
    // Associate cells with weights (number of active slots per cell)
    var extendedBuffer = Object.assign({}, slotBuffer, itemSlots)
    var extendedSlotList = Object.keys(extendedBuffer)
    var nextActiveCells = extendedSlotList.reduce(function (acc, csid) {
      // Unique cells with number of active slots
      var csc = extendedBuffer[csid]
      var cellId = ttco.cellId(csc)
      if (acc.hasOwnProperty(cellId)) {
        acc[cellId] += 1
      } else {
        acc[cellId] = 1
      }
      return acc
    }, {})

    // NOTE: ensure that nothing gets removed elsewhere.
    // Otherwise flickering will occur.

    var slotsAway = subtract(state.activeSlots, nextActiveSlots)
    if (Object.keys(slotsAway).length > 0) {
      dispatch({
        type: 'DEACTIVATE_SLOTS',
        slots: slotsAway
      })
    }

    var itemsAway = subtract(state.items, nextItems)
    if (Object.keys(itemsAway).length > 0) {
      dispatch({
        type: 'DEACTIVATE_ITEMS',
        items: itemsAway
      })
    }

    var cellsAway = subtract(state.activeCells, nextActiveCells)
    if (Object.keys(cellsAway).length > 0) {
      dispatch({
        type: 'DEACTIVATE_CELLS',
        cells: cellsAway
      })
    }
  }
}
