var tapspace = require('tapspace')
var ttcoords = require('taataa-coords')
var effects = require('../lib/effects')
var actions = require('../../../../../actions')

exports.create = function (opts) {
  // Create item and add it to space.
  //
  // Params:
  //  opts:
  //    storeItem
  //    view
  //    store: a Redux store
  //    itemGroup
  //    slotGroup
  //
  // TODO return adder image
  var item = opts.storeItem
  var view = opts.view
  var store = opts.store
  var itemGroup = opts.itemGroup

  // Html for custom file input.
  // See https://stackoverflow.com/a/17822847/638546
  // and https://tympanus.net/codrops/2015/09/15/styling-
  // customizing-file-inputs-smart-way/
  var inputId = 'img' + item.id
  var formId = 'form' + item.id
  var itemHtml = '<div><form id="' + formId + '" class="adder">' +
    '<label for="' + inputId + '"></label>' +
    '<input id="' + inputId + '" type="file" name="img" required>' +
    '</form></div>' // 2nd container for effects

  var sitem = new tapspace.SpaceHTML(itemHtml, itemGroup)
  sitem.setSize(256, 256)

  var cont = view.getElementBySpaceItem(sitem)
  var effectCont = cont.firstChild
  sitem.effectContainer = effectCont
  sitem.endEffect = effects.none()

  // Class the container for easier styling and debugging.
  cont.className += ' tt-item tt-adder'

  // Reference to item
  // NOTE we cannot just 'sitem.item = item' because the functional approach.
  sitem.itemId = item.id

  // Position
  var rect = ttcoords.cellSlotToViewRect(store.getState().origin, item.csc)
  sitem.translateScale([
    sitem.atNW(),
    sitem.atSE()
  ], [
    itemGroup.at(rect.x0, rect.y0),
    itemGroup.at(rect.x1, rect.y1)
  ])

  var fileInput = document.getElementById(inputId)
  var useCapture = false
  fileInput.addEventListener('change', function (ev) {
    var file = fileInput.files[0]
    store.dispatch(actions.taaAddRequest(item.id, file, item.csc))
  }, useCapture)

  // HACK HACK HACK
  // Prevent taps propagating further because Touchable space will
  // call preventDefault which cancels out the file opener (default behavior).
  var stopProp = function (ev) { ev.stopPropagation() }
  var propOpts = { passive: true }
  var form = document.getElementById(formId)
  form.addEventListener('touchstart', stopProp, propOpts)
  form.addEventListener('touchmove', stopProp, propOpts)
  form.addEventListener('touchend', stopProp, propOpts)
  form.addEventListener('touchcancel', stopProp, propOpts)
  form.addEventListener('mousedown', stopProp)
  form.addEventListener('mousemove', stopProp)
  form.addEventListener('mouseup', stopProp)
  form.addEventListener('mouseleave', stopProp)

  // NOTE Touch capabilities are not needed because static item.

  return sitem
}

exports.update = function (opts) {
  // Detect change and act accordingly.
  // TODO design in redux way
  //
  // Params:
  //  opts:
  //    spaceItem
  //    store: a Redux store
  //    newStoreItem
  //    oldStoreItem
  //
  var sitem = opts.spaceItem
  var nu = opts.newStoreItem.state

  if (nu === 'waitingResponse') {
    sitem.endEffect()
    sitem.endEffect = effects.loadingCcw(sitem.effectContainer)
  }

  return sitem
}

exports.remove = function (opts) {
  // Remove rendered item.
  //
  // Params:
  //   opts:
  //     spaceItem: SpaceItem to remove
  //
  var sitem = opts.spaceItem
  sitem.remove()
}
