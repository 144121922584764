var jumpInput = require('./jumpInput')
var keyboardInput = require('./keyboardInput')
var pointerInput = require('./pointerInput')
var resizeInput = require('./resizeInput')
var dropzoneInput = require('./dropzoneInput')

var slotRenderer = require('./slotRenderer')
var itemRenderer = require('./itemRenderer')

var tapspace = require('tapspace')

module.exports = function (store) {
  // Space view

  // Setup

  var space = new tapspace.Space()
  var view = new tapspace.SpaceView(space)
  view.mount(document.getElementById('space'))

  // For plain tiles
  var slotGroup = new tapspace.SpaceGroup(space)

  // For active items
  var itemGroup = new tapspace.SpaceGroup(space)

  // For inventory
  var inventoryGroup = new tapspace.SpaceGroup(space)

  pointerInput(view, store)
  keyboardInput(view, store)
  jumpInput(view, store)
  resizeInput(view, store)
  dropzoneInput(view, store)

  slotRenderer(view, store, slotGroup)
  itemRenderer(view, store, itemGroup, slotGroup, inventoryGroup)
}
