var ttcoords = require('taataa-coords')

module.exports = function (state, csc) {
  // Return a cell from given position.
  // Return null if the cell does not exist yet locally.
  var cid = ttcoords.cellId(csc)
  if (state.cells.hasOwnProperty(cid)) {
    return state.cells[cid]
  }
  return null
}
