var calculateSlot = require('cluster-key-slot')

var CELLMAP = typeof window === 'undefined' ? process.env.TAATAA_CELLMAP
  : window.taataa.CELLMAP

if (!CELLMAP) {
  throw new Error('Missing environment variable TAATAA_CELLMAP')
}

var cellmap
try {
  cellmap = JSON.parse(CELLMAP)
} catch (err) {
  throw new Error('Cell map is not valid JSON: ' + CELLMAP)
}

// NOTE Do only light validation here. Do heavy validation on the server-side
// because Ajv schema validator is a big piece of fat.
if (cellmap.length < 1) {
  throw new Error('Invalid cell map: ' + CELLMAP)
}

// Ensure correct ranges and order

var i, first, last, slot
slot = 0
for (i = 0; i < cellmap.length; i += 1) {
  first = cellmap[i][0]
  last = cellmap[i][1]
  if (first > last || last > 16383) {
    throw new Error('Invalid cell map range: ' + CELLMAP)
  }
  if (cellmap[i][0] !== slot) {
    throw new Error('Invalid cell map range: ' + CELLMAP)
  }
  slot = last + 1
}

if (slot - 1 !== 16383) {
  throw new Error('Invalid cell map range: ' + CELLMAP)
}

// Module

module.exports = function (cellId) {
  // Return cell shard info for the cellId
  var slot = calculateSlot(cellId)

  var i, firstSlot, lastSlot
  for (i = 0; i < cellmap.length; i += 1) {
    firstSlot = cellmap[i][0]
    lastSlot = cellmap[i][1]
    if (firstSlot <= slot && slot <= lastSlot) {
      return {
        slot: slot,
        slotRange: [firstSlot, lastSlot],
        cellHost: cellmap[i][2],
        tileHost: cellmap[i][3]
      }
    }
  }

  throw new Error('No cell server found for ' + cellId + '. ' +
    'Check the cell map: ' + CELLMAP)
}

module.exports.machines = function () {
  // For each slot-range-cell-tile entry
  return cellmap.map(function (rh) {
    return {
      slotRange: [rh[0], rh[1]],
      cellHost: rh[2],
      tileHost: rh[3]
    }
  })
}

module.exports.cellNodeHosts = function () {
  return module.exports.machines().map(function (machine) {
    return machine.cellHost
  })
}

module.exports.cellTileHosts = function () {
  return module.exports.machines().map(function (machine) {
    return machine.tileHost
  })
}
