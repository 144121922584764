
module.exports = function (state, action) {
  //
  // Action:
  //   type: 'TAA_MOVE_FAILED',
  //   moverId: virtual item ID,
  //   nexterId: virtual item ID,
  //   movedId: item ID,
  //   code: string
  //
  // Case:
  //   Bob picks an item.
  //   Kate picks the same item.
  //   Kate drops the item. (success)
  //   Bob drops the item. (failure: SLOT_ITEM_MISSING)
  //
  if (action.code === 'SLOT_ITEM_MISSING') {
    // Simple, eventually consistent implementation is to just remove.
    // TODO keep nexter until cell events
    var nextItems = Object.assign({}, state.items)
    delete nextItems[action.moverId]
    delete nextItems[action.nexterId]

    return Object.assign({}, state, {
      items: nextItems
    })
  }

  // TODO other cases
  return state
}
