var storeItems = require('../actions/lib/storeItems')

module.exports = function (state, action) {
  //
  var newItems = {}
  var newAdder = storeItems.adder(action.csc)
  newItems[newAdder.id] = newAdder

  return Object.assign({}, state, {
    items: Object.assign({}, state.items, newItems)
  })
}
