
module.exports = function (id, csc) {
  if (!csc) {
    throw new Error('Invalid cell slot coordinate: ' + csc)
  }

  return {
    type: 'TAA_DROPZONE_START',
    id: id,
    csc: csc
  }
}
