//
// Cell node API
//
var cellmodel = require('taataa-cellmodel')
var axios = require('axios')

exports.fetchCell = function (cellId, cb) {
  // Params
  //   cellId
  //   cb: fn(err, rawCell)
  //
  var cellUrl = cellmodel.getCellUrl(cellId)
  axios.get(cellUrl).then(function (resp) {
    return cb(null, resp.data)
  }, function (err) {
    return cb(err)
  })
}

exports.fetchCellEvents = function (cellId, clock, cb) {
  // Params:
  //   cellId
  //   clock, get events after the clock
  //   cb: fn(err, cevs)
  //     cevs: array of cell events, oldest event first
  //
  // Dev note: We need cell events to
  // - prerender and/or update tiles
  // - update cell state to know behavior of each stack (e.g. private stack)
  //
  var firstEventAt = clock + 1
  var cellUrl = cellmodel.getCellUrl(cellId)
  var eventsUrl = cellUrl + '/events/' + firstEventAt

  axios.get(eventsUrl).then(function (resp) {
    // Oldest event first.
    if (typeof resp === 'string') throw new Error('Invalid JSON response.')
    return cb(null, resp.data)
  }, function (err) {
    return cb(err)
  })
}
