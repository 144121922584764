
module.exports = function (state, action) {
  var nuCellMeta
  var olCellMeta = state.cellsMeta[action.cellId]

  if (olCellMeta) {
    nuCellMeta = Object.assign({}, olCellMeta, {
      requestState: 'FETCHING'
    })
  } else {
    nuCellMeta = {
      requestState: 'FETCHING'
    }
  }

  var metaPart = {}
  metaPart[action.cellId] = nuCellMeta

  return Object.assign({}, state, {
    cellsMeta: Object.assign({}, state.cellsMeta, metaPart)
  })
}
